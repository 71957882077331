// IMPORT REACT
import React,{ useState, useEffect } from 'react'
// IMPORT AXIOS
import axios from "axios"
// IMPORT JWT DECODE
import jwt_decode from "jwt-decode"
// IMPORT USE NAVIGATE, LINK 
import { useNavigate, Link } from "react-router-dom"
// IMPORT MENU SIDEBAR
import MenuSidebar from '../Layout/MenuSideBar.jsx'
// IMPORT MENU NAVBAR
import MenuNavbar from '../Layout/MenuNavbar.jsx'
// IMPORT MENU NAVBAR BOTTOM
import MenuNavbarBot from '../Layout/MenuNavbarBot.jsx'
// IMPORT MENU DONASI
import MenuDonasi from '../Layout/MenuDonasi.jsx'
// MENU WE CV
const MenuWeCv = () => { // BUKA KURUNG MENU WE CV
    // MEMBUAT VARIABEL MENU WE CV
    const [displayLoader, setDisplayLoader] = useState("")
    const [panggilan, setPanggilan] = useState('')
    // MEMBUAT VARIABEL EXPIRE TOKEN
    const [expire, setExpire] = useState('')
    // MEMBUAT VARIABEL TOKEN
    const [, setToken] = useState('')
    // MEMBUAT VARIABEL NAVIGATE BY USE NAVIGATE
    const navigate = useNavigate()
    // USEEFFECT
    useEffect(()=>{
        refreshToken()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // REFRESH TOKEN
    const refreshToken = async() => {
        try {
            const response = await axios.get('https://server13.webew.id/tokenBE')
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
            setDisplayLoader("none")
        } catch (error) {
            if(error.response){
                navigate("/login", { replace: true })
            }   
        }
    }
    const axiosJWT = axios.create()
    axiosJWT.interceptors.request.use(async(config) => {
        const currentDate = new Date()
        if(expire * 1000 < currentDate.getTime()){
            const response = await axios.get('https://server13.webew.id/tokenBE')
            config.headers.Authorization = `Bearer ${response.data.accessToken}`
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
        }
        return config
    }, (error)=>{
        return Promise.reject(error)
    })
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div id="wrapper">
            {/* LOADER */}
                <div className="loader-page-bg" style={{display:displayLoader}}>
                    <div className="loader-page"></div>
                </div>
            {/* TUTUP LOADER */}
            {/* MODAL DONASI */}
                <MenuDonasi/>
            {/* TUTUP MODAL DONASI */}
            {/* SIDEBAR */}
                <MenuSidebar/>
            {/* TUTUP SIDEBAR */}
            {/* KONTEN WRAP */}
                <div id="content-wrapper">
                    {/* NAVBAR */}
                        <MenuNavbar/>
                    {/* TUTUP NAVBAR */}
                    {/* KONTEN */}
                        <div className="container-fluid" style={{overflow:"auto",display:"block",width:"100%",height:"88vh"}}>
                            <h4 className="text-center mt-3" style={{fontWeight:"700"}}>Bikin CV Online</h4>
                            <section style={{marginTop:"-40px"}}>
                                <div className="row mb-5 pb-5">
                                    <div className="col-6 col-lg-4 col-xl-3 mb-2" id="website">
                                        <div className="card" style={{width: "100%",minHeight:"390px"}}>
                                            <h5 className="card-title text-center mt-2">CV G1</h5>
                                            <a href="https://webew.id/img/bikinCV/cvg1.webp" aria-label="cv" data-toggle="lightbox" data-gallery="cv"><img src="https://webew.id/img/bikinCV/cvg1.webp" className="mx-auto d-block mt-2" style={{width:"130px", minHeight:"195px"}} alt="Web portofolio gratis 1"/></a>
                                            <div className="card-body">
                                                <p className="card-text-harga">Harga Saat Ini</p>
                                                <p className="card-text-rupiah">Rp 5.000</p>
                                                <Link to={`/menu-we-cv-form/${panggilan}/CV1`} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Bikin</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-3 mb-2" id="undanganDigital">
                                        <div className="card" style={{width: "100%",minHeight:"390px"}}>
                                            <h5 className="card-title text-center mt-2">CV G2</h5>
                                            <a href="https://webew.id/img/bikinCV/cvg2.webp" aria-label="cv" data-toggle="lightbox" data-gallery="cv"><img src="https://webew.id/img/bikinCV/cvg2.webp" className="mx-auto d-block mt-2" style={{width:"130px", minHeight:"195px"}} alt="Web portofolio gratis 1"/></a>
                                            <div className="card-body">
                                                <p className="card-text-harga">Harga Saat Ini</p>
                                                <p className="card-text-rupiah">Rp 5.000</p>
                                                <Link to={`/menu-we-cv-form/${panggilan}/CV2`} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Bikin</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-3 mb-2" id="ucapanUltah">
                                        <div className="card" style={{width: "100%",minHeight:"390px"}}>
                                            <h5 className="card-title text-center mt-2">CV G3</h5>
                                            <a href="https://webew.id/img/bikinCV/cvg3.webp" aria-label="cv" data-toggle="lightbox" data-gallery="cv"><img src="https://webew.id/img/bikinCV/cvg3.webp" className="mx-auto d-block mt-2" style={{width:"130px", minHeight:"195px"}} alt="Web portofolio gratis 1"/></a>
                                            <div className="card-body">
                                                <p className="card-text-harga">Harga Saat Ini</p>
                                                <p className="card-text-rupiah">Rp 5.000</p>
                                                <Link to={`/menu-we-cv-form/${panggilan}/CV3`} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Bikin</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-3 mb-2" id="bikinCV">
                                        <div className="card" style={{width: "100%",minHeight:"390px"}}>
                                            <h5 className="card-title text-center mt-2">CV G4</h5>
                                            <a href="https://webew.id/img/bikinCV/cvg4.webp" aria-label="cv" data-toggle="lightbox" data-gallery="cv"><img src="https://webew.id/img/bikinCV/cvg4.webp" className="mx-auto d-block mt-2" style={{width:"130px", minHeight:"195px"}} alt="Web portofolio gratis 1"/></a>
                                            <div className="card-body">
                                                <p className="card-text-harga">Harga Saat Ini</p>
                                                <p className="card-text-rupiah">Rp 5.000</p>
                                                <Link to={`/menu-we-cv-form/${panggilan}/CV4`} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Bikin</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* NAVBOT */}
                                <MenuNavbarBot/>
                            </section>
                        </div>
                    {/* TUTUP KONTEN */}
                </div>
            {/* TUTUP KONTEN WRAPPER */}
        </div>
    ) // TUTUP KURUNG RETURN
} // TUTUP KURUNG MENU WE CV
// EKSPOR
export default MenuWeCv