// IMPORT REACT
import React,{ useState, useEffect } from 'react'
// IMPORT USE PARAMS
import { useParams } from 'react-router-dom'
// IMPORT NAVBAR BOTTOM
import NavbarBot from '../../Layout/NavbarBot.jsx';
// WE WEBSITE CONTOH
const WeWebsiteContoh = () => { // BUKA KURUNG WE WEBSITE CONTOH
    // MEMBUAT VARIABEL WE WEBSITE CONTOH
    const [image, setImage] = useState('')
    const { jenisWeWebsiteParams } = useParams();
    // USEEFFECT
    useEffect(()=>{
        if(jenisWeWebsiteParams === 'Website Portofolio 1'){
            setImage('https://webew.id/img/desainWebsite/ImgWebsiteG/PortoG1.webp')
        }else if(jenisWeWebsiteParams === 'Website Portofolio 2'){
            setImage('https://webew.id/img/desainWebsite/ImgWebsiteG/PortoG2.webp')
        }else if(jenisWeWebsiteParams === 'Website Portofolio 3'){
            setImage('https://webew.id/img/desainWebsite/ImgWebsiteG/PortoG3.webp')
        }else if(jenisWeWebsiteParams === 'Website Portofolio 4'){
            setImage('https://webew.id/img/desainWebsite/ImgWebsiteG/PortoG4.webp')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // STYLE CSS
    const Style = {
        imgWeb:{
            margin: '5vh 0 15px 0',
            height: '75vh',
            overflow: 'auto',
            backgroundColor: 'black',
            borderRadius: '10px',
        }
    }
    // RETURN
    return ( //BUKA KURUNG RETURN
        <div className='background-home' style={{marginTop:'55px'}}>
            {/* INFO */}
                <section id='home'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 col-lg-6 px-3'>
                                <h3 className='title-webew-home'>{jenisWeWebsiteParams}</h3>
                                <p className='text-webew-home'>Website portofolio yang memiliki 1 halaman dan sudah responsive di segala device dan juga terdapat Animate On Scroll.</p>
                                <div className='row'>
                                    <div className='col-6'>
                                        <a href='/we-website' className='btn btn-webew-home' aria-label='Kembali webew.id'>Kembali</a>
                                    </div>
                                    <div className='col-6'>
                                        <a href='https://webew.id/login' className='btn btn-webew-home' aria-label='Produk webew.id'><i className='bi bi-download'></i> Download</a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-lg-6'style={Style.imgWeb}>
                                <img src={image} className='img-fluid mx-auto d-block' width='480px' alt='by julos on freepik'/>
                            </div>
                        </div>
                    </div>    
                </section>
            {/* TUTUP INFO */}
            {/* NAVBAR BOTTOM */}
                <NavbarBot/>
            {/* TUTUP NAVBAR BOTTOM */}
        </div>
    ) // TUTUP KURUNG RETURN
} // TUTUP KURUNG WE WEBSITE CONTOH
// EKSPOR
export default WeWebsiteContoh