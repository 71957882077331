// IMPORT REACT
import React,{ useState, useEffect } from 'react'
// IMPORT AXIOS
import axios from "axios"
// IMPORT JWT DECODE
import jwt_decode from "jwt-decode"
// IMPORT USE NAVIGATE, LINK
import { useNavigate, Link } from "react-router-dom"
// MENU SIDE BAR
const MenuSideBar = () => { // KURUNG BUKA MENU SIDE BAR
     // MEMBUAT VARIABEL PANGGILAN
     const [panggilan, setPanggilan] = useState('')
     // MEMBUAT VARIABEL TOKEN
     const [, setToken] = useState('')
     // MEMBUAT VARIABEL EXPIRE TOKEN
     const [expire, setExpire] = useState('')
     // MEMBUAT VARIABEL NAVIGATE BY USE NAVIGATE
     const navigate = useNavigate()
     // USE EFFECT
     useEffect(()=>{
         refreshToken()
         // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [])
    //  REFRESH TOKEN
     const refreshToken = async() => {
         try {
             const response = await axios.get('https://server13.webew.id/tokenBE')
             setToken(response.data.accessToken)
             const decoded = jwt_decode(response.data.accessToken)
             setPanggilan(decoded.panggilan)
             setExpire(decoded.exp)
         } catch (error) {
             if(error.response){
                 navigate("/login", { replace: true })
             }   
         }
     }
     const axiosJWT = axios.create()
     axiosJWT.interceptors.request.use(async(config) => {
         const currentDate = new Date()
         if(expire * 1000 < currentDate.getTime()){
             const response = await axios.get('https://server13.webew.id/tokenBE')
             config.headers.Authorization = `Bearer ${response.data.accessToken}`
             setToken(response.data.accessToken)
             const decoded = jwt_decode(response.data.accessToken)
             setPanggilan(decoded.panggilan)
             setExpire(decoded.exp)
         }
         return config
     }, (error)=>{
         return Promise.reject(error)
     })
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div>
            {/* SIDE BAR */}
            <nav className='navbar-expand-sm bg-navbar'>
                <div className='dropdown ml-2'></div>
                <div className='collapse navbar-collapse' id='sidebar'>
                    <ul className='navbar-nav sidebar' id='accordionSidebar'>
                        <div className='navbar-brand d-flex align-items-center justify-content-center'>
                            <h5 className=' mt-4' style={{fontWeight:'700'}}>&copy;webew.id</h5>
                        </div>
                        <div className='sidebar-heading'>Menu Webew</div>
                        <li className='nav-item nav-itemm'>
                            <Link className='nav-link nav-linkk' to={`/dashboard/${panggilan}`}><i className='bi bi-columns-gap'></i><span>Dashboard</span></Link>
                        </li>
                        <li className='nav-item nav-itemm'>
                            <Link className='nav-link nav-linkk' to={`/menu-we-aqiqah/${panggilan}`}><i className='bi bi-columns-gap'></i><span>We Aqiqah</span></Link>
                        </li>
                        <li className='nav-item nav-itemm'>
                            <Link className='nav-link nav-linkk' to={`/menu-we-cv/${panggilan}`}><i className='bi bi-columns-gap'></i><span>We CV</span></Link>
                        </li>
                        <li className='nav-item nav-itemm'>
                            <Link className='nav-link nav-linkk' to={`/menu-we-khodam/${panggilan}`}><i className='bi bi-columns-gap'></i><span>We Khodam</span></Link>
                        </li>
                        <li className='nav-item nav-itemm'>
                            <Link className='nav-link nav-linkk' to={`/menu-we-motivasi/${panggilan}`}><i className='bi bi-columns-gap'></i><span>We Motivasi</span></Link>
                        </li>
                        <li className='nav-item nav-itemm'>
                            <Link className='nav-link nav-linkk' to={`/menu-we-pernikahan/${panggilan}`}><i className='bi bi-columns-gap'></i><span>We Pernikahan</span></Link>
                        </li>
                        <li className='nav-item nav-itemm'>
                            <Link className='nav-link nav-linkk' to={`/menu-we-ultah/${panggilan}`}><i className='bi bi-columns-gap'></i><span>We Ultah</span></Link>
                        </li>
                        <li className='nav-item nav-itemm'>
                            <Link className='nav-link nav-linkk' to={`/menu-we-website/${panggilan}`}><i className='bi bi-columns-gap'></i><span>We Website</span></Link>
                        </li>
                    </ul>
                </div>
            </nav>
            {/* TUTUP SIDE BAR */}
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP MENU SIDE BAR
// EKSPOR
export default MenuSideBar