// IMPORT REACT
import React,{ useState, useEffect } from 'react'
// IMPORT AXIOS
import axios from "axios"
// IMPORT JWT DECODE
import jwt_decode from "jwt-decode"
// IMPORT USE NAVIGATE, LINK
import { useNavigate, Link } from "react-router-dom"
// MENU NAVBAR BOTTOM
const MenuNavbarBot = () => { // KURUNG BUKA MENU NAVBAR BOTTOM
    // MEMBUAT VARIABEL PANGGILAN
    const [panggilan, setPanggilan] = useState('')
    // MEMBUAT VARIABEL TOKEN
    const [, setToken] = useState('')
    // MEMBUAT VARIABEL EXPIRE TOKEN
    const [expire, setExpire] = useState('')
    // MEMBUAT NAVIGATE BY USE NAVIGATE
    const navigate = useNavigate()
    // USE EFFECT
    useEffect(()=>{
        refreshToken()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // REFRESH TOKEN
    const refreshToken = async() => {
        try {
            const response = await axios.get('https://server13.webew.id/tokenBE')
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
        } catch (error) {
            if(error.response){
                navigate("/login", { replace: true })
            }   
        }
    }
    const axiosJWT = axios.create()
    axiosJWT.interceptors.request.use(async(config) => {
        const currentDate = new Date()
        if(expire * 1000 < currentDate.getTime()){
            const response = await axios.get('https://server13.webew.id/tokenBE')
            config.headers.Authorization = `Bearer ${response.data.accessToken}`
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
        }
        return config
    }, (error)=>{
        return Promise.reject(error)
    })
    // LOGOUT
    const Logout = async() =>{
        try {
            await axios.delete('https://server13.webew.id/logoutBE')
            navigate("/login", { replace: true })
        } catch (error) {
            console.log(error)
        }
    }
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div>
            {/* NAVBAR BOTTOM */}
            <div className="navbot">
                <div className="container fixed-bottom" style={{width:"100%",height:"auto",background:"white", padding:"10px 15px 10px 15px",borderRadius:"50px",boxShadow: "0 0 20px 7px #dddddd53"}}>
                    <div className="row">
                        <div className="col-4"><Link to={`/dashboard/${panggilan}`} className="btn btn-webew-product" style={{padding:"4px 0 4px 0",marginTop:"3px"}} aria-label="Produk webew.id"><i class="bi bi-house-up" style={{color:"white"}}></i></Link></div>
                        <div className="col-4">
                            <div class="dropdown-center">
                                <button class="btn btn-webew-product" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{padding:"7px 0 7px 0"}}><i class="bi bi-grid-fill" style={{color:"white"}}></i></button>
                                <ul class="dropdown-menu text-center mb-3">
                                    <li><Link to={`/menu-we-aqiqah/${panggilan}`} className="dropdown-item" aria-label="We Aqiqah" style={{fontSize:"13pt"}}>Menu We Aqiqah</Link></li>
                                    <li><Link to={`/menu-we-cv/${panggilan}`} className="dropdown-item" aria-label="We CV" style={{fontSize:"13pt"}}>Menu We CV</Link></li>
                                    <li><Link to={`/menu-we-khodam/${panggilan}`} className="dropdown-item" aria-label="We Khodam" style={{fontSize:"13pt"}}>Menu We Khodam</Link></li>
                                    <li><Link to={`/menu-we-motivasi/${panggilan}`} className="dropdown-item" aria-label="We Motivasi" style={{fontSize:"13pt"}}>Menu We Motivasi</Link></li>
                                    <li><Link to={`/menu-we-pernikahan/${panggilan}`} className="dropdown-item" aria-label="We Pernikahan" style={{fontSize:"13pt"}}>Menu We Pernikahan</Link></li>
                                    <li><Link to={`/menu-we-ultah/${panggilan}`} className="dropdown-item" aria-label="We Ultah" style={{fontSize:"13pt"}}>Menu We Ultah</Link></li>
                                    <li><Link to={`/menu-we-website/${panggilan}`} className="dropdown-item" aria-label="We Website" style={{fontSize:"13pt"}}>Menu We Website</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-4"><button onClick={Logout} className="btn btn-webew-product" style={{padding:"4px 0 4px 0",marginTop:"3px"}} aria-label="Produk webew.id"><i class="bi bi-power" style={{color:"white"}}></i></button></div>
                    </div>
                </div>
            </div>
            {/* TUTUP NAVBAR BOTTOM */}
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP MENU NAVBAR BOTTOM
// EKSPOR
export default MenuNavbarBot