// IMPORT REACT
import React from 'react'
// IMPORT NAVBAR BOTTOM
import NavbarBot from '../../Layout/NavbarBot.jsx'
// WE CV
const WeCv = () => { // KURUNG BUKA WE CV
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div className='background-home' style={{marginTop:'55px'}}>
            {/* INFO */}
                <section id='home'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 col-lg-6 px-3'>
                                <h2 className='title-webew-home'>Bikin CV Online</h2>
                                <p className='text-webew-home'>Bikin CV online dengan mudah dan cepat disini, tinggal pilih desain, isi form data dengan benar, lalu download, dan kamu sudah memiliki cv</p>
                                <div className='row'>
                                    <div className='col-6'>
                                        <a href='/' className='btn btn-webew-home' aria-label='Kembali webew.id'>Kembali</a>
                                    </div>
                                    <div className='col-6'>
                                        <a href='#bikinCV' className='btn btn-webew-home' aria-label='Produk webew.id'>Desain</a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-lg-6'>
                                <img src='https://webew.id/img/6976378_4565.webp' className='img-webew-home mx-auto d-block mt-4' style={{width: '100%',minHeight:'200px'}} alt='by Freepik on freepik'/>
                            </div>
                        </div>
                    </div>    
                </section>
            {/* TUTUP INFO */}
            <img src='https://webew.id/img/bg-lengkung.png' className='img-bg-lengkung'  style={{width: '100%',minHeight:'360px'}} alt='bg lengkung'/>
            {/* JENIS WE CV */}
                <section id='bikinCV' style={{marginTop:'-70px'}}>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-6 col-md-4 col-lg-3' id='website'>
                                <div className='card' style={{width: '100%',minHeight:'390px'}}>
                                    <h5 className='card-title text-center mt-2'>CV G1</h5>
                                    <a href='img/bikinCV/cvg1.webp' aria-label='cv' data-toggle='lightbox' data-gallery='cv'><img src='img/bikinCV/cvg1.webp' className='mx-auto d-block mt-2' style={{width:'130px', minHeight:'195px'}} alt='Bikin CV G1'/></a>
                                    <div className='card-body'>
                                        <p className='card-text-harga mt-3'>Harga Saat Ini</p>
                                        <p className='card-text-rupiah'>Rp 5.000</p>
                                        <a href='https://webew.id/login' className='btn btn-webew-product mt-1 mb-1' aria-label='Produk webew.id'>Bikin</a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 col-md-4 col-lg-3' id='undanganDigital'>
                                <div className='card' style={{width: '100%',minHeight:'390px'}}>
                                    <h5 className='card-title text-center mt-2'>CV G2</h5>
                                    <a href='img/bikinCV/cvg2.webp' aria-label='cv' data-toggle='lightbox' data-gallery='cv'><img src='img/bikinCV/cvg2.webp' className='mx-auto d-block mt-2' style={{width:'130px', minHeight:'195px'}} alt='Bikin CV G2'/></a>
                                    <div className='card-body'>
                                        <p className='card-text-harga mt-3'>Harga Saat Ini</p>
                                        <p className='card-text-rupiah'>Rp 5.000</p>
                                        <a href='https://webew.id/login' className='btn btn-webew-product mt-1 mb-1' aria-label='Produk webew.id'>Bikin</a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 col-md-4 col-lg-3' id='ucapanUltah'>
                                <div className='card' style={{width: '100%',minHeight:'390px'}}>
                                    <h5 className='card-title text-center mt-2'>CV G3</h5>
                                    <a href='img/bikinCV/cvg3.webp' aria-label='cv' data-toggle='lightbox' data-gallery='cv'><img src='img/bikinCV/cvg3.webp' className='mx-auto d-block mt-2' style={{width:'130px', minHeight:'195px'}} alt='Bikin CV G3'/></a>
                                    <div className='card-body'>
                                        <p className='card-text-harga mt-3'>Harga Saat Ini</p>
                                        <p className='card-text-rupiah'>Rp 5.000</p>
                                        <a href='https://webew.id/login' className='btn btn-webew-product mt-1 mb-1' aria-label='Produk webew.id'>Bikin</a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 col-md-4 col-lg-3' id='bikinCV'>
                                <div className='card' style={{width: '100%',minHeight:'390px'}}>
                                    <h5 className='card-title text-center mt-2'>CV G4</h5>
                                    <a href='img/bikinCV/cvg4.webp' aria-label='cv' data-toggle='lightbox' data-gallery='cv'><img src='img/bikinCV/cvg4.webp' className='mx-auto d-block mt-2' style={{width:'130px', minHeight:'195px'}} alt='Bikin CV G4'/></a>
                                    <div className='card-body'>
                                        <p className='card-text-harga mt-3'>Harga Saat Ini</p>
                                        <p className='card-text-rupiah'>Rp 5.000</p>
                                        <a href='https://webew.id/login' className='btn btn-webew-product mt-1 mb-1' aria-label='Produk webew.id'>Bikin</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            {/* TUTUP JENIS WE CV */}
            <img src='https://webew.id/img/bg-lengkung.png' className='img-bg-lengkung-bawah'  style={{width: '100%',minHeight:'360px'}} alt='bg lengkung'/>
            {/* CREDIT IMAGE */}
                <section id='credit-image'>
                    <div className='container mb-4'>
                        <h3 className='judul-credit text-center'>Image By</h3>
                        <div className='row justify-content-center'>
                            <div className='col-8 col-sm-6 col-md-4 col-lg-3 mb-3'>
                                <a href='https://www.freepik.com/free-vector/recruit-agent-analyzing-candidates_6976378.htm#page=4&position=14&from_view=author' aria-label='Link mengarah ke pembuat image yaitu Pch-vector Freepik'>
                                    <div className='card' style={{width: '100%',minHeight:'150px'}}>
                                        <img src='https://webew.id/img/credit/6976378_4565.webp' className='mx-auto d-block'  style={{width: '100%',minHeight:'120px'}} alt='by Pch-vector on Freepik'/>
                                        <div className='card-body'>
                                            <p className='text-center'>Pch-vector on Freepik</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            {/* TUTUP CREIT IMAGE */}
            {/* NAVBAR BOTTOM */}
                <NavbarBot/>
            {/* NAVBAR BOTTOM */}
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP WE CV
// EKSPOR
export default WeCv