import React,{ useState, useEffect } from "react"
import axios from "axios"
import { useNavigate } from "react-router-dom"

const Login = () => {
    const [userName, setUserName] = useState("")
    const [password, setPassword] = useState("")
    const [msg, setMsg] = useState("")
    const [displayLoader, setDisplayLoader] = useState("")
    const navigate = useNavigate()

    useEffect(()=>{
        setDisplayLoader("none")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const Auth = async(e)=>{
        e.preventDefault()
        try {
            setDisplayLoader("")
            await axios.post("https://server13.webew.id/MapLoginBE",{
                userName: userName,
                password: password
            })
            navigate("/menu-dashboard-promap", { replace: true })
            // setMsg("Maaf dalam Pemeliharaan")
        } catch (error) {
            if(error.response){
                setDisplayLoader("none")
                setMsg(error.response.data.msg)
            }
        }
    }
    // Halaman
    return (
        <div>
            <div className="loader-page-bg" style={{display:displayLoader}}>
                <div className="loader-page"></div>
            </div>
            <div className="container mb-4" style={{marginTop:"40px"}}>
                <div className="row justify-content-center">
                    <div className="col-11 col-sm-8 col-md-6 col-lg-5 col-xl-4">
                        <div className="box-login">
                            <h4 className="text-center" style={{fontWeight:"700"}}>Login ProMAP</h4>
                            <form onSubmit={Auth}>
                                <h6 style={{textAlign:"center",marginTop:"15px",marginBottom:"-10px",color:"red"}}>{msg}</h6>
                                <label htmlFor="userName" className="mx-2"></label>
                                <input type="text" id="userName" className="form-control form mb-2" placeholder="Masukan username" value={userName} onChange={(e) => setUserName(e.target.value)} required autoFocus/>
                                <label htmlFor="password" className="mx-2"></label>
                                <input type="password" id="password" className="form-control form mb-4" placeholder="Masukan password" value={password} onChange={(e) => setPassword(e.target.value)} required/>
                                <input type="submit" className="btn btn-webew-login mb-4 mt-3" value="Login"/>
                                <a href="/menu-register" className="pt-2" style={{color:"rgb(0, 86, 247)", margin:"auto", display:"block", textAlign:"center", fontSize:"11pt"}}>Belum punya akun ? daftar disini</a>
                                <a href="/menu-lupa-password" style={{color:"rgb(0, 86, 247)", margin:"auto", display:"block", textAlign:"center", fontSize:"11pt"}}>Lupa Password ? klik disini</a>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login