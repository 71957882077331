// IMPORT REACT
import React,{ useState, useEffect } from "react"
// IMPORT AXIOS
import axios from "axios"
// IMPORT JWT DECODE
import jwt_decode from "jwt-decode"
// IMPORT USE NAVIGATE
import { useNavigate } from "react-router-dom"
// MENU DASHBOARD
const MenuDashboard = () => { // KURUNG BUKA MENU DASHBOARD
    // MEMBUAT VARIABEL EXPIRE TOKEN
    const [expire, setExpire] = useState("")
    // MEMBUAT VARIABEL TOKEN
    const [, setToken] = useState("")
    // MEMBUAT NAVIGATE BY USE NAVIGATE
    const navigate = useNavigate()
    // USEEFFECT
    useEffect(()=>{
        refreshToken()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // REFRESH TOKEN
    const refreshToken = async() => {
        try {
            const response = await axios.get("https://server13.webew.id/tokenBE")
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setExpire(decoded.exp)
            navigate(`/dashboard/${decoded.panggilan}`, { replace: true })
        } catch (error) {
            if(error.response){
                navigate("/login", { replace: true })
            }   
        }
    }
    const axiosJWT = axios.create()
    axiosJWT.interceptors.request.use(async(config) => {
        const currentDate = new Date()
        if(expire * 1000 < currentDate.getTime()){
            const response = await axios.get("https://server13.webew.id/tokenBE")
            config.headers.Authorization = `Bearer ${response.data.accessToken}`
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setExpire(decoded.exp)
        }
        return config
    }, (error)=>{
        return Promise.reject(error)
    })
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div id="background-home">
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP MENU DASHBOARD
// EKSPOR
export default MenuDashboard