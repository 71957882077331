// IMPORT REACT
import React from 'react'
// IMPORT LINK
import { Link } from 'react-router-dom'
// IMPORT NAVBAR BOTTOM
import NavbarBot from '../../Layout/NavbarBot.jsx'
// WE WEBSITE
const WeWebsite = () => { // BUKA KURUNG WE WEBSITE
    // RETURN
    return ( // BUKA KURUNG RETURN
        <div className='background-home' style={{marginTop:'55px'}}>
            {/* INFO */}
                <section id='home'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 col-lg-6 px-3'>
                                <h2 className='title-webew-home'>Desain Website</h2>
                                <p className='text-webew-home'>Pilih desain website yang kamu inginkan, lalu tinggal kamu download, gampang sekali bukan. Buruan download desain website disini</p>
                                <div className='row'>
                                    <div className='col-6'>
                                        <a href='/' className='btn btn-webew-home' aria-label='Kembali webew.id'>Kembali</a>
                                    </div>
                                    <div className='col-6'>
                                        <a href='#desainWebsite' className='btn btn-webew-home' aria-label='Produk webew.id'>Desain</a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-lg-6'>
                                <img src='https://webew.id/img/25559969_7094814.webp' className='img-webew-home mx-auto d-block mt-5'  style={{width: '100%',minHeight:'200px'}} alt='by Freepik on freepik'/>
                            </div>
                        </div>
                    </div>    
                </section>
            {/* TUTUP INFO */}
            <img src='https://webew.id/img/bg-lengkung.png' className='img-bg-lengkung'  style={{width: '100%',minHeight:'360px'}} alt='bg lengkung'/>
            {/* JENIS WE WEBSITE */}
            <section id='desainWebsite' style={{marginTop:'-70px'}}>
                <div className='container'>
                    <div className='row pt-5'>
                        <div className='col-6 col-md-4 col-lg-3' id='website'>
                            <div className='card' style={{width: '100%',minHeight:'390px'}}>
                                <h5 className='card-title text-center mt-2'>Web Porto G1</h5>
                                <img src='https://webew.id/img/desainWebsite/web-porto-g1.png' className='card-img-top mx-auto d-block' style={{width:'130px',minHeight:'175px'}} alt='Website portofolio G1'/>
                                <div className='card-body'>
                                    <table className='mb-2 mt-1'>
                                        <tbody>
                                            <tr>
                                                <td width='33,3%'><img src='https://webew.id/img/desainWebsite/HTML 5.png' className='img-fluid mx-auto d-block' style={{width:'40px',minHeight:'40px'}} alt='Logo HTML 5'/></td>
                                                <td width='33,3%'><img src='https://webew.id/img/desainWebsite/css3-logo-png-transparent.png' className='img-fluid mx-auto d-block' style={{width:'40px',minHeight:'40px'}} alt='Logo CSS 3'/></td>
                                                <td width='33,4%'><img src='https://webew.id/img/desainWebsite/bootstrap-logo.png' className='img-fluid mx-auto d-block' style={{width:'40px',minHeight:'40px'}} alt='Logo Bootstrap 5'/></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p className='card-text-harga'>Harga Saat Ini</p>
                                    <p className='card-text-rupiah'>Rp 5.000</p>
                                    <Link to={'/we-website/Website Portofolio 1'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-6 col-md-4 col-lg-3' id='undanganDigital'>
                            <div className='card' style={{width: '100%',minHeight:'390px'}}>
                                <h5 className='card-title text-center mt-2'>Web Porto G2</h5>
                                <img src='https://webew.id/img/desainWebsite/web-porto-g2.png' className='card-img-top mx-auto d-block' style={{width:'130px',minHeight:'175px'}} alt='Website portofolio G2'/>
                                <div className='card-body'>
                                    <table className='mb-2 mt-1'>
                                        <tbody>
                                            <tr>
                                                <td width='33,3%'><img src='https://webew.id/img/desainWebsite/HTML 5.png' className='img-fluid mx-auto d-block' style={{width:'40px',minHeight:'40px'}} alt='Logo HTML 5'/></td>
                                                <td width='33,3%'><img src='https://webew.id/img/desainWebsite/css3-logo-png-transparent.png' className='img-fluid mx-auto d-block' style={{width:'40px',minHeight:'40px'}} alt='Logo CSS 3'/></td>
                                                <td width='33,4%'><img src='https://webew.id/img/desainWebsite/bootstrap-logo.png' className='img-fluid mx-auto d-block' style={{width:'40px',minHeight:'40px'}} alt='Logo Bootstrap 5'/></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p className='card-text-harga'>Harga Saat Ini</p>
                                    <p className='card-text-rupiah'>Rp 5.000</p>
                                    <Link to={'/we-website/Website Portofolio 2'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-6 col-md-4 col-lg-3' id='ucapanUltah'>
                            <div className='card' style={{width: '100%',minHeight:'390px'}}>
                                <h5 className='card-title text-center mt-2'>Web Porto G3</h5>
                                <img src='https://webew.id/img/desainWebsite/web-porto-g3.png' className='card-img-top mx-auto d-block' style={{width:'130px',minHeight:'175px'}} alt='Website portofolio G3'/>
                                <div className='card-body'>
                                    <table className='mb-2 mt-1'>
                                        <tbody>
                                            <tr>
                                                <td width='33,3%'><img src='https://webew.id/img/desainWebsite/HTML 5.png' className='img-fluid mx-auto d-block' style={{width:'40px',minHeight:'40px'}} alt='Logo HTML 5'/></td>
                                                <td width='33,3%'><img src='https://webew.id/img/desainWebsite/css3-logo-png-transparent.png' className='img-fluid mx-auto d-block' style={{width:'40px',minHeight:'40px'}} alt='Logo CSS 3'/></td>
                                                <td width='33,4%'><img src='https://webew.id/img/desainWebsite/bootstrap-logo.png' className='img-fluid mx-auto d-block' style={{width:'40px',minHeight:'40px'}} alt='Logo Bootstrap 5'/></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p className='card-text-harga'>Harga Saat Ini</p>
                                    <p className='card-text-rupiah'>Rp 5.000</p>
                                    <Link to={'/we-website/Website Portofolio 3'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-6 col-md-4 col-lg-3' id='bikinCV'>
                            <div className='card' style={{width: '100%',minHeight:'390px'}}>
                                <h5 className='card-title text-center mt-2'>Web Porto G4</h5>
                                <img src='https://webew.id/img/desainWebsite/web-porto-g4.png' className='card-img-top mx-auto d-block' style={{width:'130px',minHeight:'175px'}} alt='Website portofolio G4'/>
                                <div className='card-body'>
                                    <table className='mb-2 mt-1'>
                                        <tbody>
                                            <tr>
                                                <td width='33,3%'><img src='https://webew.id/img/desainWebsite/HTML 5.png' className='img-fluid mx-auto d-block' style={{width:'40px',minHeight:'40px'}} alt='Logo HTML 5'/></td>
                                                <td width='33,3%'><img src='https://webew.id/img/desainWebsite/css3-logo-png-transparent.png' className='img-fluid mx-auto d-block' style={{width:'40px',minHeight:'40px'}} alt='Logo CSS 3'/></td>
                                                <td width='33,4%'><img src='https://webew.id/img/desainWebsite/bootstrap-logo.png' className='img-fluid mx-auto d-block' style={{width:'40px',minHeight:'40px'}} alt='Logo Bootstrap 5'/></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p className='card-text-harga'>Harga Saat Ini</p>
                                    <p className='card-text-rupiah'>Rp 5.000</p>
                                    <Link to={'/we-website/Website Portofolio 4'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* TUTUP JENIS WE WEBSITE */}
            <img src='https://webew.id/img/bg-lengkung.png' className='img-bg-lengkung-bawah'  style={{width: '100%',minHeight:'360px'}} alt='bg lengkung'/>
            {/* CREDIT IMAGE */}
                <section id='credit-image'>
                    <div className='container mb-4'>
                        <h3 className='judul-credit text-center'>Image By</h3>
                        <div className='row justify-content-center'>
                            <div className='col-8 col-sm-6 col-md-4 col-lg-3 mb-3'>
                                <a href='https://www.freepik.com/free-vector/hand-drawn-flat-design-api-illustration_25559969.htm#page=4&query=programming&position=7&from_view=author' aria-label='Link mengarah ke pembuat image yaitu Freepik di Freepik'>
                                    <div className='card' style={{width: '100%',minHeight:'150px'}}>
                                        <img src='https://webew.id/img/credit/25559969_7094814.webp' className='mx-auto d-block'  style={{width: '100%',minHeight:'110px'}} alt='by Freepik on Freepik'/>
                                        <div className='card-body'>
                                            <p className='text-center'>Freepik on Freepik</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            {/* TUTUP CREDIT IMAGE */}
            {/* NAVBARBOT */}
                <NavbarBot/>
            {/* TUTUP NAVBAR BOTTOM */}
        </div>
    ) // TUTUP KURUNG RETURN
} // TUTUP KURUNG WE WEBSITE
// EKSPOR
export default WeWebsite