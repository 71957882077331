// IMPORT REACT
import React,{ useState, useEffect } from 'react'
// IMPORT AXIOS
import axios from "axios"
// IMPORT USE PARAMS
import { useParams } from "react-router-dom"
// IMPORT USE NAVIGATE
import { useNavigate } from "react-router-dom"
// MENU NAVBAR
const MenuNavbar = () => { // KURUNG BUKA MENU NAVBAR
    // MEMBUAT VARIABEL URL FOTO
    const [urlFoto, setUrlFoto] = useState("")
    // MEMBUAT VARIABEL PARAMS BY PANGGILAN
    const { panggilanParams } = useParams();
    // MEMBUAT VARIABEL NAVIGATE BY USE NAVIGATE
    const navigate = useNavigate()
    // USE EFFECT
    useEffect(()=>{
        selectWeUsers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // LOGOUT
    const Logout = async() =>{
        try {
            await axios.delete('https://server13.webew.id/logoutBE')
            navigate("/login", { replace: true })
        } catch (error) {
            console.log(error)
        }
    }
    // MENAMPILKAN WE USERS BY PARAMS PANGGILAN
    const selectWeUsers = async() =>{
        try{
            const response = await axios.get(`https://server13.webew.id/selectWeUsersBE/${panggilanParams}`)
            setUrlFoto(response.data.urlFoto)
        }catch(error){
            if(error.response){
                console.log(error.response.data.msg)
            }
        }
    }
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div>
            {/* NAVBAR */}
            <nav className='navbar navbar-expand-sm sticky-top nav-bar py-3'>
                <div className='container'>
                    <div className='dropdown ml-2'>
                        <button className='navbar-toggler' data-bs-toggle='collapse' data-bs-target='#sidebar' aria-controls='sidebar' aria-expanded='false' aria-label='Toggle navigation' style={{border:'none'}}>
                            <i className='bi bi-grid-fill' style={{fontSize:'22px'}}></i>
                        </button>
                    </div>
                    <ul className='navbar-nav me-auto mb-2 mb-lg-0'></ul>
                    <div className='d-flex' role='search'>
                        <button type='button' className='btn btn-webew-product mx-2' data-bs-toggle='modal' data-bs-target='#staticBackdropDonasi' style={{padding:"0 7.5px 0 7.5px"}}><p className='bi bi-coin'style={{color:'white',fontSize:'20px',margin:'0',fontWeight:'600'}}></p></button>
                        { urlFoto === null
                            ? <img src='https://webew.id/img/default.jpg' className='mx-auto d-block' alt='foto' style={{minWidth:'35px',maxWidth:'35px',borderRadius:'50%'}}/>
                            : <img src={urlFoto} className='mx-auto d-block' alt='foto' style={{minWidth:'35px',maxWidth:'35px',borderRadius:'50%'}}/>
                        }
                        <button onClick={Logout} className='btn btn-logout mx-2'><i className='bi bi-power' style={{color:'white',fontSize:'22px'}}></i></button>
                    </div>
                </div>
            </nav>
            {/* TUTUP NAVBAR */}
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP MENU NAVBAR
// EKSPOR
export default MenuNavbar