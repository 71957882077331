// IMPORT REACT
import React,{ useState, useEffect } from 'react'
// IMPORT AXIOS
import axios from "axios"
// IMPORT JWT DECODE
import jwt_decode from "jwt-decode"
// IMPORT USE NAVIGATE, LINK
import { useNavigate, Link } from "react-router-dom"
// IMPORT MENU SIDEBAR
import MenuSidebar from '../Layout/MenuSideBar.jsx'
// IMPORT MENU NAVBAR
import MenuNavbar from '../Layout/MenuNavbar.jsx'
// IMPORT MENU NAVBAR BOTTOM
import MenuNavbarBot from '../Layout/MenuNavbarBot.jsx'
// IMPORT MENU DONASI
import MenuDonasi from '../Layout/MenuDonasi.jsx'
// MENU WE PERNIKAHAN
const MenuWePernikahan = () => { // KURUNG BUKA MENU WE PERNIKAHAN
    // MEMBUAT VARIABEL WE PERNIKAHAN
    const [displayLoader, setDisplayLoader] = useState("")
    const [panggilan, setPanggilan] = useState('')
    // MEMBUAT VARIABEL EXPIRE TOKEN
    const [expire, setExpire] = useState('')
    // MEMBUAT VARIABEL TOKEN
    const [, setToken] = useState('')
    // MEMBUAT VARIABEL NAVIGATE BY USE NAVIGATE
    const navigate = useNavigate()
    // USEEFFECT
    useEffect(()=>{
        refreshToken()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // REFRESH TOKEN
    const refreshToken = async() => {
        try {
            const response = await axios.get('https://server13.webew.id/tokenBE')
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
            setDisplayLoader("none")
        } catch (error) {
            if(error.response){
                navigate("/login", { replace: true })
            }   
        }
    }
    const axiosJWT = axios.create()
    axiosJWT.interceptors.request.use(async(config) => {
        const currentDate = new Date()
        if(expire * 1000 < currentDate.getTime()){
            const response = await axios.get('https://server13.webew.id/tokenBE')
            config.headers.Authorization = `Bearer ${response.data.accessToken}`
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
        }
        return config
    }, (error)=>{
        return Promise.reject(error)
    })
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div id="wrapper">
            {/* LOADER */}
                <div className="loader-page-bg" style={{display:displayLoader}}>
                    <div className="loader-page"></div>
                </div>
            {/* TUTUP LOADER */}
            {/* MODAL DONASI */}
                <MenuDonasi/>
            {/* TUTUP MODAL DONASI */}
            {/* SIDEBAR */}
                <MenuSidebar/>
            {/* TUTUP SIDEBAR */}
            {/* KONTEN WRAPPER */}
                <div id="content-wrapper">
                    {/* NAVBAR */}
                        <MenuNavbar/>
                    {/* TUTUP NAVBAR */}
                    {/* KONTEN */}
                        <div className="container-fluid" style={{overflow:"auto",display:"block",width:"100%",height:"88vh"}}>
                            <h4 className="text-center mt-3" style={{fontWeight:"700"}}>We Pernikahan</h4>
                            <section className=' mb-5 pb-5' style={{marginTop:"20px"}}>
                                <h6 className='fw-bold text-center card-text-rupiah'>Note : Tidak ada masa aktif ya, jadi bisa lihat kapan saja</h6>
                                <div className="row mb-5" style={{marginTop:"-40px"}}>
                                    <div className="col-6 col-lg-4 col-xl-3" id="undanganDigital">
                                        <div className="card" style={{width: "100%",minHeight:"390px"}}>
                                            <h5 className="card-title text-center mt-2">Aglaonema</h5>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/aglaonema.png" className="mx-auto d-block mt-1" style={{width:"125px", minHeight:"175px"}} alt="Web portofolio gratis 1"/>
                                            <div className="card-body">
                                                <p className="card-text-harga">Harga Saat Ini</p>
                                                <p className="card-text-rupiah">Rp 45.000</p>
                                                <div className="row">
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={'/we-pernikahan/aglaonema'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={`/menu-we-pernikahan-form/${panggilan}/aglaonema`} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Bikin</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-3" id="ucapanUltah">
                                        <div className="card" style={{width: "100%",minHeight:"390px"}}>
                                            <h5 className="card-title text-center mt-2">Agapanthus</h5>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/agapanthus.png" className="mx-auto d-block mt-1" style={{width:"125px", minHeight:"175px"}} alt="Web portofolio gratis 1"/>
                                            <div className="card-body">
                                                <p className="card-text-harga">Harga Saat Ini</p>
                                                <p className="card-text-rupiah">Rp 45.000</p>
                                                <div className="row">
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={'/we-pernikahan/agapanthus'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={`/menu-we-pernikahan-form/${panggilan}/agapanthus`} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Bikin</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-3" id="bikinCV">
                                        <div className="card" style={{width: "100%",minHeight:"390px"}}>
                                            <h5 className="card-title text-center mt-2">Alamanda</h5>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/alamanda.png" className="mx-auto d-block mt-1" style={{width:"125px", minHeight:"175px"}} alt="Web portofolio gratis 1"/>
                                            <div className="card-body">
                                                <p className="card-text-harga">Harga Saat Ini</p>
                                                <p className="card-text-rupiah">Rp 45.000</p>
                                                <div className="row">
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={'/we-pernikahan/alamanda'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={`/menu-we-pernikahan-form/${panggilan}/alamanda`} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Bikin</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-3" id="bikinCV">
                                        <div className="card" style={{width: "100%",minHeight:"390px"}}>
                                            <h5 className="card-title text-center mt-2">Amaryllis</h5>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/amaryllis.png" className="mx-auto d-block mt-1" style={{width:"125px", minHeight:"175px"}} alt="Web portofolio gratis 1"/>
                                            <div className="card-body">
                                                <p className="card-text-harga">Harga Saat Ini</p>
                                                <p className="card-text-rupiah">Rp 45.000</p>
                                                <div className="row">
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={'/we-pernikahan/amaryllis'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={`/menu-we-pernikahan-form/${panggilan}/amaryllis`} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Bikin</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-6 col-lg-4 col-xl-3" id="bikinCV">
                                        <div className="card" style={{width: "100%",minHeight:"390px"}}>
                                            <h5 className="card-title text-center mt-2">Bnw 1</h5>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bnw1.png" className="mx-auto d-block mt-1" style={{width:"125px", minHeight:"175px"}} alt="Web portofolio gratis 1"/>
                                            <div className="card-body">
                                                <p className="card-text-harga">Harga Saat Ini</p>
                                                <p className="card-text-rupiah">Rp 45.000</p>
                                                <div className="row">
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={'/we-pernikahan/bnw1'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={`/menu-we-pernikahan-form/${panggilan}/bnw1`} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Bikin</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-3" id="bikinCV">
                                        <div className="card" style={{width: "100%",minHeight:"390px"}}>
                                            <h5 className="card-title text-center mt-2">Bnw 2</h5>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bnw2.png" className="mx-auto d-block mt-1" style={{width:"125px", minHeight:"175px"}} alt="Web portofolio gratis 1"/>
                                            <div className="card-body">
                                                <p className="card-text-harga">Harga Saat Ini</p>
                                                <p className="card-text-rupiah">Rp 45.000</p>
                                                <div className="row">
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={'/we-pernikahan/bnw2'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={`/menu-we-pernikahan-form/${panggilan}/bnw2`} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Bikin</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-3" id="bikinCV">
                                        <div className="card" style={{width: "100%",minHeight:"390px"}}>
                                            <h5 className="card-title text-center mt-2">Bnw 3</h5>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bnw3.png" className="mx-auto d-block mt-1" style={{width:"125px", minHeight:"175px"}} alt="Web portofolio gratis 1"/>
                                            <div className="card-body">
                                                <p className="card-text-harga">Harga Saat Ini</p>
                                                <p className="card-text-rupiah">Rp 45.000</p>
                                                <div className="row">
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={'/we-pernikahan/bnw3'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={`/menu-we-pernikahan-form/${panggilan}/bnw3`} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Bikin</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-3" id="bikinCV">
                                        <div className="card" style={{width: "100%",minHeight:"390px"}}>
                                            <h5 className="card-title text-center mt-2">Bnw 4</h5>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bnw4.png" className="mx-auto d-block mt-1" style={{width:"125px", minHeight:"175px"}} alt="Web portofolio gratis 1"/>
                                            <div className="card-body">
                                                <p className="card-text-harga">Harga Saat Ini</p>
                                                <p className="card-text-rupiah">Rp 45.000</p>
                                                <div className="row">
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={'/we-pernikahan/bnw4'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={`/menu-we-pernikahan-form/${panggilan}/bnw4`} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Bikin</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-6 col-lg-4 col-xl-3" id="bikinCV">
                                        <div className="card" style={{width: "100%",minHeight:"390px"}}>
                                            <h5 className="card-title text-center mt-2">Fotografi</h5>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/fotografi.png" className="mx-auto d-block mt-1" style={{width:"125px", minHeight:"175px"}} alt="Web portofolio gratis 1"/>
                                            <div className="card-body">
                                                <p className="card-text-harga">Harga Saat Ini</p>
                                                <p className="card-text-rupiah">Rp 45.000</p>
                                                <div className="row">
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={'/we-pernikahan/fotografi'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={`/menu-we-pernikahan-form/${panggilan}/fotografi`} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Bikin</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-3" id="bikinCV">
                                        <div className="card" style={{width: "100%",minHeight:"390px"}}>
                                            <h5 className="card-title text-center mt-2">Bike</h5>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bike.png" className="mx-auto d-block mt-1" style={{width:"125px", minHeight:"175px"}} alt="Web portofolio gratis 1"/>
                                            <div className="card-body">
                                                <p className="card-text-harga">Harga Saat Ini</p>
                                                <p className="card-text-rupiah">Rp 45.000</p>
                                                <div className="row">
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={'/we-pernikahan/bike'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={`/menu-we-pernikahan-form/${panggilan}/bike`} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Bikin</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-3" id="bikinCV">
                                        <div className="card" style={{width: "100%",minHeight:"390px"}}>
                                            <h5 className="card-title text-center mt-2">Football</h5>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/football.png" className="mx-auto d-block mt-1" style={{width:"125px", minHeight:"175px"}} alt="Web portofolio gratis 1"/>
                                            <div className="card-body">
                                                <p className="card-text-harga">Harga Saat Ini</p>
                                                <p className="card-text-rupiah">Rp 45.000</p>
                                                <div className="row">
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={'/we-pernikahan/football'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={`/menu-we-pernikahan-form/${panggilan}/football`} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Bikin</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-3" id="bikinCV">
                                        <div className="card" style={{width: "100%",minHeight:"390px"}}>
                                            <h5 className="card-title text-center mt-2">Badminton</h5>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/badminton.png" className="mx-auto d-block mt-1" style={{width:"125px", minHeight:"175px"}} alt="Web portofolio gratis 1"/>
                                            <div className="card-body">
                                                <p className="card-text-harga">Harga Saat Ini</p>
                                                <p className="card-text-rupiah">Rp 45.000</p>
                                                <div className="row">
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={'/we-pernikahan/badminton'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={`/menu-we-pernikahan-form/${panggilan}/badminton`} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Bikin</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-6 col-lg-4 col-xl-3" id="bikinCV">
                                        <div className="card" style={{width: "100%",minHeight:"390px"}}>
                                            <h5 className="card-title text-center mt-2">BleGold</h5>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/blegold.png" className="mx-auto d-block mt-1" style={{width:"125px", minHeight:"175px"}} alt="Web portofolio gratis 1"/>
                                            <div className="card-body">
                                                <p className="card-text-harga">Harga Saat Ini</p>
                                                <p className="card-text-rupiah">Rp 45.000</p>
                                                <div className="row">
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={'/we-pernikahan/blegold'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={`/menu-we-pernikahan-form/${panggilan}/blegold`} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Bikin</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-3" id="bikinCV">
                                        <div className="card" style={{width: "100%",minHeight:"390px"}}>
                                            <h5 className="card-title text-center mt-2">BlePink</h5>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/blepink.png" className="mx-auto d-block mt-1" style={{width:"125px", minHeight:"175px"}} alt="Web portofolio gratis 1"/>
                                            <div className="card-body">
                                                <p className="card-text-harga">Harga Saat Ini</p>
                                                <p className="card-text-rupiah">Rp 45.000</p>
                                                <div className="row">
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={'/we-pernikahan/blepink'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={`/menu-we-pernikahan-form/${panggilan}/blepink`} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Bikin</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-3" id="bikinCV">
                                        <div className="card" style={{width: "100%",minHeight:"390px"}}>
                                            <h5 className="card-title text-center mt-2">BleTosca</h5>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bletosca.png" className="mx-auto d-block mt-1" style={{width:"125px", minHeight:"175px"}} alt="Web portofolio gratis 1"/>
                                            <div className="card-body">
                                                <p className="card-text-harga">Harga Saat Ini</p>
                                                <p className="card-text-rupiah">Rp 45.000</p>
                                                <div className="row">
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={'/we-pernikahan/bletosca'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={`/menu-we-pernikahan-form/${panggilan}/bletosca`} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Bikin</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-3" id="bikinCV">
                                        <div className="card" style={{width: "100%",minHeight:"390px"}}>
                                            <h5 className="card-title text-center mt-2">BleWhite</h5>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/blewhite.png" className="mx-auto d-block mt-1" style={{width:"125px", minHeight:"175px"}} alt="Web portofolio gratis 1"/>
                                            <div className="card-body">
                                                <p className="card-text-harga">Harga Saat Ini</p>
                                                <p className="card-text-rupiah">Rp 45.000</p>
                                                <div className="row">
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={'/we-pernikahan/blewhite'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={`/menu-we-pernikahan-form/${panggilan}/blewhite`} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Bikin</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-6 col-lg-4 col-xl-3" id="bikinCV">
                                        <div className="card" style={{width: "100%",minHeight:"390px"}}>
                                            <h5 className="card-title text-center mt-2">Cute</h5>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/cute.png" className="mx-auto d-block mt-1" style={{width:"125px", minHeight:"175px"}} alt="Web portofolio gratis 1"/>
                                            <div className="card-body">
                                                <p className="card-text-harga">Harga Saat Ini</p>
                                                <p className="card-text-rupiah">Rp 45.000</p>
                                                <div className="row">
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={'/we-pernikahan/cute'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={`/menu-we-pernikahan-form/${panggilan}/cute`} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Bikin</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-3" id="bikinCV">
                                        <div className="card" style={{width: "100%",minHeight:"390px"}}>
                                            <h5 className="card-title text-center mt-2">Adorable</h5>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/adorable.png" className="mx-auto d-block mt-1" style={{width:"125px", minHeight:"175px"}} alt="Web portofolio gratis 1"/>
                                            <div className="card-body">
                                                <p className="card-text-harga">Harga Saat Ini</p>
                                                <p className="card-text-rupiah">Rp 45.000</p>
                                                <div className="row">
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={'/we-pernikahan/adorable'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={`/menu-we-pernikahan-form/${panggilan}/adorable`} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Bikin</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-3" id="bikinCV">
                                        <div className="card" style={{width: "100%",minHeight:"390px"}}>
                                            <h5 className="card-title text-center mt-2">Beauty</h5>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/beauty.png" className="mx-auto d-block mt-1" style={{width:"125px", minHeight:"175px"}} alt="Web portofolio gratis 1"/>
                                            <div className="card-body">
                                                <p className="card-text-harga">Harga Saat Ini</p>
                                                <p className="card-text-rupiah">Rp 45.000</p>
                                                <div className="row">
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={'/we-pernikahan/beauty'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={`/menu-we-pernikahan-form/${panggilan}/beauty`} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Bikin</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-3 mb-2" id="bikinCV">
                                        <div className="card" style={{width: "100%",minHeight:"390px"}}>
                                            <h5 className="card-title text-center mt-2">Cool</h5>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/cool.png" className="mx-auto d-block mt-1" style={{width:"125px", minHeight:"175px"}} alt="Web portofolio gratis 1"/>
                                            <div className="card-body">
                                                <p className="card-text-harga">Harga Saat Ini</p>
                                                <p className="card-text-rupiah">Rp 45.000</p>
                                                <div className="row">
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={'/we-pernikahan/cool'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={`/menu-we-pernikahan-form/${panggilan}/cool`} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Bikin</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </section>
                            {/* NAVBOT */}
                            <MenuNavbarBot/>
                        </div>
                    {/* TUTUP KONTEN */}
                </div>
            {/* TUTUP KONTEN WRAPPER */}
        </div>
    ) // TUTUP KURUNG RETURN
} // TUTUP KURUNG MENU WE PERNIKAHAN
// EKSPOR
export default MenuWePernikahan