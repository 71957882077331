// IMPORT REACT
import React from 'react'
// IMPORT REACT DOM
import ReactDOM from 'react-dom/client'
// IMPORT APP
import App from './App'
// IMPORT AXIOS
import axios from "axios"
// IMPORT BOOTSRAP MIN
import 'bootstrap/dist/css/bootstrap.min.css';
// IMPORT BOOTSRAP BUNDLE MIN
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// IMPORT BOOTSRAP ICONS
import 'bootstrap-icons/font/bootstrap-icons.css';
// IMPORT AOS
import "aos/dist/aos.css";
// IMPORT WE CV CSS
import "./CvG.css"
// IMPORT HOME CSS
import "./Home.css"
// IMPORT NAVBAR CSS
import "./Navbar.css"
// IMPORT NAVBAR MAP CSS
import "./NavbarMap.css"
// IMPORT SIDEBAR CSS
import "./Sidebar.css"
// AXIOS CREDENTIALS
axios.defaults.withCredentials = true
// MEMBUAT VARIABEL ROOT
const root = ReactDOM.createRoot(document.getElementById('root'))
// RENDER ROOT
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)