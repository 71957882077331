// IMPORT REACT
import React, {useState} from 'react'
// IMPORT AXIOS
import axios from 'axios'
// IMPORT NAVBAR BOTTOM
import NavbarBot from '../Layout/NavbarBot.jsx'
// HOME
const Home = () => { // KURUNG BUKA HOME
    // MEMBUAT VARIABEL HOME
    const [name,] = useState('webewers')
    const [saran, setSaran] = useState('')
    // PROSES MENYIMPAN SARAN
    const createSaran = async (e) => {
        e.preventDefault()
        try{
            await axios.post('https://server13.webew.id/createSaranBE',{
                saran: saran,
                nama: name
            })
            window.location.reload()
        } catch(error){
            if(error.response){
                console.log(error.response.data.msg)
            }
        }
    }
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div className='background-home' style={{marginTop:'55px'}}>
            {/* HOME */}
                <section id='home'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 col-lg-6 px-4'>
                                <h1 className='title-webew-home'>webew.id</h1>
                                <p className='text-webew-home'>Selalu ada dan selalu siap membantu wujudkan kebutuhan dan keinginan anda dengan berbagai aplikasi di dalam website ini</p>
                                <a href='/#produk' className='btn btn-webew-home' aria-label='Produk webew.id'>Produk Kami</a>
                            </div>
                            <div className='col-12 col-lg-6'>
                                <img src='https://webew.id/img/fun-3d-illustration-cartoon-kid-with-vr-helmet.jpg' className='img-fluid img-webew-home mx-auto d-block' width='480px' alt='by julos on freepik'/>
                            </div>
                        </div>
                    </div>    
                </section>
            {/* TUTUP HOME */}
            <img src='https://webew.id/img/bg-lengkung.png' className='img-bg-lengkung'  style={{width: '100%',minHeight:'360px'}} alt='bg lengkung'/>
            {/* ABOUT */}
                <section id='about' style={{marginTop:'-70px'}}>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 col-md-6'>
                                <img src='https://webew.id/img/fun-3d-illustration-cartoon-kid-with-vr-helmet (1).jpg' className='img-fluid mx-auto d-block' width='480px' alt='by julos on freepik'/>
                            </div>
                            <div className='col-12 col-md-6'>
                                <h2 className='judul-about px-4'>Layanan Terbaik</h2>
                                <p className='teks-about px-4'>Kami akan memberikan beberapa layanan aplikasi terbaik yang kami miliki kepada kamu. Mulai dari design website, membuat CV secara online, membuat undangan digital, dan membuat ucapan ulang tahun.</p>
                            </div>
                        </div>
                        <div className='row mt-5 pt-5'>
                            <div className='col-12 col-md-6 order-1 order-md-0'>
                                <h2 className='judul-about px-4'>Harganya Murah</h2>
                                <p className='teks-about px-4'>Tidak perlu pusing untuk memikirkan harga, karena disini kami memberikan harga yang sangat ramah di kantong. Dan jangan khawatir dengan kualitas, karna disini kualitas tetap menjadi nomer satu.</p>
                            </div>
                            <div className='col-12 col-md-6 order-0 order-md-1'>
                                <img src='https://webew.id/img/fun-cartoon-kid-with-rain-gear.jpg' className='img-fluid mx-auto d-block' width='480px' alt='by julos on freepik' style={{transform: 'scalex(-1)'}}/>
                            </div>
                        </div>
                    </div>
                </section>
            {/* TUTUP ABOUT */}
            {/* PRODUK */}
                <section id='produk'>
                    <div className='container'>
                        <div className='row pt-4'>
                            <div className='col-12 col-sm-6 col-md-4 col-lg-3' id='website'>
                                <div className='card'  style={{width: '100%',minHeight:'450px'}}>
                                    <img src='https://webew.id/img/25559969_7094814.webp' className='card-img-top' style={{width: '100%',minHeight:'150px'}} alt='by Freepik on Freepik'/>
                                    <div className='card-body'>
                                        <h5 className='card-title text-center'>We Website</h5>
                                        <p className='card-text mt-3'>Ayo dapatkan desain website terbaik dengan berbagai jenis website yang sesuai dengan kebutuhan kamu.</p>
                                        <p className='card-text-harga'>Harga Saat Ini</p>
                                        <p className='card-text-rupiah'>Rp 5.000  <s>Rp 7.000</s></p>
                                        <a href='/we-website' className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-sm-6 col-md-4 col-lg-3' id='undanganDigital'>
                                <div className='card'  style={{width: '100%',minHeight:'450px'}}>
                                    <img src='https://webew.id/img/19212427_Business people arranging appointment in digital booking app.webp' className='card-img-top' style={{width: '100%',minHeight:'150px'}} alt='by Pch-vector on Freepik'/>
                                    <div className='card-body'>
                                        <h5 className='card-title text-center'>We Aqiqah</h5>
                                        <p className='card-text mt-3'>Bikin undangan digital Aqiqah disini, pilih tema undangan digital, isi form data, dan undangan digital sudah jadi.</p>
                                        <p className='card-text-harga'>Harga Saat Ini</p>
                                        <p className='card-text-rupiah'>Rp 45.000  <s>Rp 50.000</s></p>
                                        <a href='/we-aqiqah' className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-sm-6 col-md-4 col-lg-3' id='undanganDigital'>
                                <div className='card'  style={{width: '100%',minHeight:'450px'}}>
                                    <img src='https://webew.id/img/19212427_Business people arranging appointment in digital booking app.webp' className='card-img-top' style={{width: '100%',minHeight:'150px'}} alt='by Pch-vector on Freepik'/>
                                    <div className='card-body'>
                                        <h5 className='card-title text-center'>We Pernikahan</h5>
                                        <p className='card-text mt-3'>Bikin undangan digital Pernikahan disini, pilih tema undangan digital, isi form data, dan undangan digital sudah jadi.</p>
                                        <p className='card-text-harga'>Harga Saat Ini</p>
                                        <p className='card-text-rupiah'>Rp 45.000  <s>Rp 50.000</s></p>
                                        <a href='/we-pernikahan' className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-sm-6 col-md-4 col-lg-3' id='ucapanUltah'>
                                <div className='card'  style={{width: '100%',minHeight:'450px'}}>
                                    <img src='https://webew.id/img/11668440_20943572.webp' className='card-img-top' style={{width: '100%',minHeight:'150px'}} alt='by Vectorjuice on Freepik'/>
                                    <div className='card-body'>
                                        <h5 className='card-title text-center'>We Ultah</h5>
                                        <p className='card-text mt-3'>Bikin ucapan ulang tahun secara online dan berikan kepada orang tersayang, teman, atau saudara kamu di website ini.</p>
                                        <p className='card-text-harga'>Harga Saat Ini</p>
                                        <p className='card-text-rupiah'>Gratis</p>
                                        <a href='/we-ultah' className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-sm-6 col-md-4 col-lg-3' id='bikinCV'>
                                <div className='card'  style={{width: '100%',minHeight:'450px'}}>
                                    <img src='https://webew.id/img/6976378_4565.webp' className='card-img-top' style={{width: '100%',minHeight:'150px'}} alt='by Pch-vector on Freepik'/>
                                    <div className='card-body'>
                                        <h5 className='card-title text-center'>We CV</h5>
                                        <p className='card-text mt-3'>kamu susah membuat cv sendiri, ayo bikin cv secara online di sini, kamu hanya perlu memilih desain dan mengisi form.</p>
                                        <p className='card-text-harga'>Harga Saat Ini</p>
                                        <p className='card-text-rupiah'>Rp 5.000  <s>Rp 7.000</s></p>
                                        <a href='/we-cv' className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-sm-6 col-md-4 col-lg-3' id='bikinCV'>
                                <div className='card'  style={{width: '100%',minHeight:'450px'}}>
                                    <img src='https://webew.id/img/4167279_18774.webp' className='card-img-top' style={{width: '100%',minHeight:'150px'}} alt='by Pch-vector on Freepik'/>
                                    <div className='card-body'>
                                        <h5 className='card-title text-center'>We Motivasi</h5>
                                        <p className='card-text mt-3'>Bikin kata-kata motivasi lalu upload kata motivasinya, selain itu kamu juga bisa melihat semua motivasi yang ada.</p>
                                        <p className='card-text-harga'>Harga Saat Ini</p>
                                        <p className='card-text-rupiah'>Gratis</p>
                                        <a href='/we-motivasi' className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-sm-6 col-md-4 col-lg-3' id='website'>
                                <div className='card'  style={{width: '100%',minHeight:'450px'}}>
                                    <img src='https://webew.id/img/25559969_7094814.webp' className='card-img-top' style={{width: '100%',minHeight:'150px'}} alt='by Freepik on Freepik'/>
                                    <div className='card-body'>
                                        <h5 className='card-title text-center'>We Khodam</h5>
                                        <p className='card-text mt-3'>Jika kalian penasaran dibadan kalian ada khodam apa, cek disini. Hasil khodam disini cuma untuk hiburan saja ya.</p>
                                        <p className='card-text-harga'>Harga Saat Ini</p>
                                        <p className='card-text-rupiah'>Gratis</p>
                                        <a href='/we-khodam' className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            {/* TUTUP PRODUK */}
            {/* SARAN */}
                <section id='saran' className='mb-5'>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-7">
                                <div className='box p-3 mt-4' style={{width: '100%',minHeight:'100px'}}>
                                    <form onSubmit={createSaran} className='mt-3'>
                                        <div className='container'>
                                            <div className='row'>
                                                <h6 className='card-title text-center mb-3'>Beri saran untuk kemajuan webew</h6>
                                                <div className='col-12 col-md-6'>
                                                    <input type='text' className='form-control form mb-3' value={saran} onChange={(e)=> setSaran(e.target.value)} required placeholder='Beri saran'/>
                                                    <input type='text' className='form-disable form mb-3' value={name} readOnly/>
                                                </div>
                                                <div className='col-12 col-md-6 mb-2'>
                                                    <input type='submit' className='btn btn-webew-login mb-2' value='Kirim Saran'/>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            {/* TUTUP SARAN */}
            <img src='https://webew.id/img/bg-lengkung.png' className='img-bg-lengkung-bawah'  style={{width: '100%',minHeight:'360px',marginTop:'0px'}} alt='bg lengkung'/>
            {/* CREDIT IMAGE */}
                <section id='credit-image'>
                    <div className='container mb-4'>
                        <h3 className='judul-credit text-center'>Image By</h3>
                        <div className='row'>
                            <div className='col-6 col-md-4 mb-3'>
                                <a href='https://www.freepik.com/free-photo/fun-3d-illustration-cartoon-kid-with-vr-helmet_14201029.htm#&position=2&from_view=author' aria-label='Link mengarah ke pembuat image yaitu Julos di Freepik'>
                                    <div className='card'  style={{width: '100%',minHeight:'180px'}}>
                                        <img src='https://webew.id/img/credit/fun-3d-illustration-cartoon-kid-with-vr-helmet.jpg' className='mx-auto d-block pt-3'  style={{width: '66%',minHeight:'90px'}} alt='by Julos on Freepik'/>
                                        <div className='card-body'>
                                            <p className='text-center'>Julos On Freepik</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className='col-6 col-md-4 mb-3'>
                                <a href='https://www.freepik.com/free-photo/fun-3d-illustration-cartoon-kid-with-vr-helmet_14201016.htm#&position=7&from_view=author' aria-label='Link mengarah ke pembuat image yaitu Julos di Freepik'>
                                    <div className='card'  style={{width: '100%',minHeight:'180px'}}>
                                        <img src='https://webew.id/img/credit/fun-3d-illustration-cartoon-kid-with-vr-helmet (1).jpg' className='mx-auto d-block pt-3'  style={{width: '70%',minHeight:'90px'}} alt='by Julos on Freepik'/>
                                        <div className='card-body'>
                                            <p className='text-center'>Julos On Freepik</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className='col-6 col-md-4 mb-3'>
                                <a href='https://www.freepik.com/free-photo/fun-cartoon-kid-with-rain-gear_14200919.htm#&position=13&from_view=author' aria-label='Link mengarah ke pembuat image yaitu Julos di Freepik'>
                                    <div className='card'  style={{width: '100%',minHeight:'180px'}}>
                                        <img src='https://webew.id/img/credit/fun-cartoon-kid-with-rain-gear.jpg' className='mx-auto d-block pt-3'  style={{width: '70%',minHeight:'90px'}} alt='by Julos on Freepik'/>
                                        <div className='card-body'>
                                            <p className='text-center'>Julos On Freepik</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className='col-6 col-md-4 mb-3'>
                                <a href='https://www.freepik.com/free-vector/hand-drawn-flat-design-api-illustration_25559969.htm#page=4&query=programming&position=7&from_view=author' aria-label='Link mengarah ke pembuat image yaitu Freepik di Freepik'>
                                    <div className='card'  style={{width: '100%',minHeight:'180px'}}>
                                        <img src='https://webew.id/img/credit/25559969_7094814.webp' className='mx-auto d-block pt-4' style={{width: '100%',minHeight:'90px'}} alt='by Freepik on Freepik'/>
                                        <div className='card-body'>
                                            <p className='text-center'>Freepik on Freepik</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className='col-6 col-md-4 mb-3'>
                                <a href='https://www.freepik.com/free-vector/business-people-arranging-appointment-digital-booking-app_19212427.htm#page=4&query=app&position=22&from_view=author' aria-label='Link mengarah ke pembuat image yaitu Pch-vector Freepik'>
                                    <div className='card'  style={{width: '100%',minHeight:'180px'}}>
                                        <img src='https://webew.id/img/credit/19212427_Business people arranging appointment in digital booking app.webp' className='mx-auto d-bloc pt-4' style={{width: '100%',minHeight:'90px'}} alt='by Pch-vector on Freepik'/>
                                        <div className='card-body'>
                                            <p className='text-center'>Pch-vector on Freepik</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className='col-6 col-md-4 mb-3'>
                                <a href='https://www.freepik.com/free-vector/signing-contract-official-document-agreement-deal-commitment-businessmen-cartoon-characters-shaking-hands-legal-contract-with-signature-concept-illustration_11668440.htm#page=4&position=21&from_view=author' aria-label='Link mengarah ke pembuat image yaitu Vectorjuice di Freepik'>
                                    <div className='card'  style={{width: '100%',minHeight:'180px'}}>
                                        <img src='https://webew.id/img/credit/11668440_20943572.webp' className='mx-auto d-block pt-4' style={{width: '100%',minHeight:'90px'}} alt='by Vectorjuice on Freepik'/>
                                        <div className='card-body'>
                                            <p className='text-center'>Vectorjuice on Freepik</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className='col-6 col-md-4 mb-3'>
                                <a href='https://www.freepik.com/free-vector/recruit-agent-analyzing-candidates_6976378.htm#page=4&position=14&from_view=author' aria-label='Link mengarah ke pembuat image yaitu Pch-vector Freepik'>
                                    <div className='card'  style={{width: '100%',minHeight:'180px'}}>
                                        <img src='https://webew.id/img/credit/6976378_4565.webp' className='mx-auto d-block pt-4' style={{width: '100%',minHeight:'90px'}} alt='by Pch-vector on Freepik'/>
                                        <div className='card-body'>
                                            <p className='text-center'>Pch-vector on Freepik</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className='col-6 col-md-4 mb-3'>
                                <a href='https://www.freepik.com/free-vector/marketing-group-working-business-solution-flat-icon_4167279.htm#from_view=detail_alsolike' aria-label='Link mengarah ke pembuat image yaitu katemangostar Freepik'>
                                    <div className='card'  style={{width: '100%',minHeight:'180px'}}>
                                        <img src='https://webew.id/img/credit/4167279_18774.webp' className='mx-auto d-block pt-4' style={{width: '100%',minHeight:'90px'}} alt='by katemangostar on Freepik'/>
                                        <div className='card-body'>
                                            <p className='text-center'>katemangostar on Freepik</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            {/* TUTUP CREDIT IMAGE */}
            {/* NAVBAR BOTTOM */}
                <NavbarBot/>
            {/* TUTUP NAVBAR BOTTOM */}
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP HOME
// EKSPOR
export default Home