// IMPORT REACT
import React from 'react'
// IMPORT LINK
import { Link } from 'react-router-dom'
// IMPORT NAVBAR BOTTOM
import NavbarBot from '../../Layout/NavbarBot.jsx'
// WE AQIQAH
const WeAqiqah = () => { // BUKA KURUNG WE AQIQAH
    // RETURN
    return ( // BUKA KURUNG RETURN
        <div className='background-home' style={{marginTop:'55px'}}>
            {/* INFO */}
                <section id='home'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 col-lg-6 px-3'>
                                <h2 className='title-webew-home'>UD Aqiqah</h2>
                                <p className='text-webew-home'>Bikin undangan digital disini, tinggal pilih tema undangan digital, isi form data dengan benar, dan undangan digital sudah jadi</p>
                                <div className='row'>
                                    <div className='col-6'>
                                        <a href='/' className='btn btn-webew-home' aria-label='Kembali webew.id'>Kembali</a>
                                    </div>
                                    <div className='col-6'>
                                        <a href='#undanganDigital' className='btn btn-webew-home' aria-label='Produk webew.id'>Desain</a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-lg-6'>
                                <img src='https://webew.id/img/19212427_Business people arranging appointment in digital booking app.webp' className='img-webew-home mx-auto d-block mt-4' style={{width: '100%',minHeight:'200px'}} alt='by Freepik on freepik'/>
                            </div>
                        </div>
                    </div>    
                </section>
            {/* TUTUP INFO */}
            <img src='https://webew.id/img/bg-lengkung.png' className='img-bg-lengkung'  style={{width: '100%',minHeight:'360px'}} alt='bg lengkung'/>
            {/* JENIS WE AQIQAH */}
                <section id='undanganDigital' style={{marginTop:'-70px'}}>
                    <div className='container'>
                        <h6 className='fw-bold text-center card-text-rupiah'>Note : Tidak ada masa aktif ya, kamu bisa membuka kapan saja jika mau</h6>
                        <div className='row'>
                            <div className='col-6 col-md-4 col-lg-3' id='undanganDigital'>
                                <div className='card' style={{width: '100%',minHeight:'390px'}}>
                                    <h5 className='card-title text-center mt-2'>white 1</h5>
                                    <img src='img/undanganDigital/UndanganDigitalAqiqah/white1.png' className='mx-auto d-block mt-1' style={{width:'125px', minHeight:'175px'}} alt='Undangan Digital aqiqah Aglaonema'/>
                                    <div className='card-body'>
                                        <p className='card-text-harga'>Harga Saat Ini</p>
                                        <p className='card-text-rupiah'>Rp 45.000</p>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6'>
                                                <Link to={'/we-aqiqah/white1'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <a href='https://webew.id/login' className='btn btn-webew-product mt-1 mb-1' aria-label='Produk webew.id'>Buat</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 col-md-4 col-lg-3' id='undanganDigital'>
                                <div className='card' style={{width: '100%',minHeight:'390px'}}>
                                    <h5 className='card-title text-center mt-2'>White 2</h5>
                                    <img src='img/undanganDigital/UndanganDigitalAqiqah/white2.png' className='mx-auto d-block mt-1' style={{width:'125px', minHeight:'175px'}} alt='Undangan Digital aqiqah Agapanthus'/>
                                    <div className='card-body'>
                                        <p className='card-text-harga'>Harga Saat Ini</p>
                                        <p className='card-text-rupiah'>Rp 45.000</p>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6'>
                                                <Link to={'/we-aqiqah/white2'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <a href='https://webew.id/login' className='btn btn-webew-product mt-1 mb-1' aria-label='Produk webew.id'>Buat</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 col-md-4 col-lg-3' id='undanganDigital'>
                                <div className='card' style={{width: '100%',minHeight:'390px'}}>
                                    <h5 className='card-title text-center mt-2'>White 3</h5>
                                    <img src='img/undanganDigital/UndanganDigitalAqiqah/white3.png' className='mx-auto d-block mt-1' style={{width:'125px', minHeight:'175px'}} alt='Undangan Digital aqiqah Alamanda'/>
                                    <div className='card-body'>
                                        <p className='card-text-harga'>Harga Saat Ini</p>
                                        <p className='card-text-rupiah'>Rp 45.000</p>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6'>
                                                <Link to={'/we-aqiqah/white3'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <a href='https://webew.id/login' className='btn btn-webew-product mt-1 mb-1' aria-label='Produk webew.id'>Buat</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 col-md-4 col-lg-3' id='undanganDigital'>
                                <div className='card' style={{width: '100%',minHeight:'390px'}}>
                                    <h5 className='card-title text-center mt-2'>White 4</h5>
                                    <img src='img/undanganDigital/UndanganDigitalAqiqah/white4.png' className='mx-auto d-block mt-1' style={{width:'125px', minHeight:'175px'}} alt='Undangan Digital aqiqah Amaryllis'/>
                                    <div className='card-body'>
                                        <p className='card-text-harga'>Harga Saat Ini</p>
                                        <p className='card-text-rupiah'>Rp 45.000</p>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6'>
                                                <Link to={'/we-aqiqah/white4'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <a href='https://webew.id/login' className='btn btn-webew-product mt-1 mb-1' aria-label='Produk webew.id'>Buat</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-6 col-md-4 col-lg-3' id='undanganDigital'>
                                <div className='card' style={{width: '100%',minHeight:'390px'}}>
                                    <h5 className='card-title text-center mt-2'>Flower 1</h5>
                                    <img src='img/undanganDigital/UndanganDigitalAqiqah/flower1.png' className='mx-auto d-block mt-1' style={{width:'125px', minHeight:'175px'}} alt='Undangan Digital aqiqah Amaryllis'/>
                                    <div className='card-body'>
                                        <p className='card-text-harga'>Harga Saat Ini</p>
                                        <p className='card-text-rupiah'>Rp 45.000</p>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6'>
                                                <Link to={'/we-aqiqah/flower1'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <a href='https://webew.id/login' className='btn btn-webew-product mt-1 mb-1' aria-label='Produk webew.id'>Buat</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 col-md-4 col-lg-3' id='undanganDigital'>
                                <div className='card' style={{width: '100%',minHeight:'390px'}}>
                                    <h5 className='card-title text-center mt-2'>Flower 2</h5>
                                    <img src='img/undanganDigital/UndanganDigitalAqiqah/flower2.png' className='mx-auto d-block mt-1' style={{width:'125px', minHeight:'175px'}} alt='Undangan Digital aqiqah Amaryllis'/>
                                    <div className='card-body'>
                                        <p className='card-text-harga'>Harga Saat Ini</p>
                                        <p className='card-text-rupiah'>Rp 45.000</p>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6'>
                                                <Link to={'/we-aqiqah/flower2'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <a href='https://webew.id/login' className='btn btn-webew-product mt-1 mb-1' aria-label='Produk webew.id'>Buat</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 col-md-4 col-lg-3' id='undanganDigital'>
                                <div className='card' style={{width: '100%',minHeight:'390px'}}>
                                    <h5 className='card-title text-center mt-2'>Flower 3</h5>
                                    <img src='img/undanganDigital/UndanganDigitalAqiqah/flower3.png' className='mx-auto d-block mt-1' style={{width:'125px', minHeight:'175px'}} alt='Undangan Digital aqiqah Amaryllis'/>
                                    <div className='card-body'>
                                        <p className='card-text-harga'>Harga Saat Ini</p>
                                        <p className='card-text-rupiah'>Rp 45.000</p>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6'>
                                                <Link to={'/we-aqiqah/flower3'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <a href='https://webew.id/login' className='btn btn-webew-product mt-1 mb-1' aria-label='Produk webew.id'>Buat</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 col-md-4 col-lg-3' id='undanganDigital'>
                                <div className='card' style={{width: '100%',minHeight:'390px'}}>
                                    <h5 className='card-title text-center mt-2'>Flower 4</h5>
                                    <img src='img/undanganDigital/UndanganDigitalAqiqah/flower4.png' className='mx-auto d-block mt-1' style={{width:'125px', minHeight:'175px'}} alt='Undangan Digital aqiqah Amaryllis'/>
                                    <div className='card-body'>
                                        <p className='card-text-harga'>Harga Saat Ini</p>
                                        <p className='card-text-rupiah'>Rp 45.000</p>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6'>
                                                <Link to={'/we-aqiqah/flower4'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <a href='https://webew.id/login' className='btn btn-webew-product mt-1 mb-1' aria-label='Produk webew.id'>Buat</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-6 col-md-4 col-lg-3' id='undanganDigital'>
                                <div className='card' style={{width: '100%',minHeight:'390px'}}>
                                    <h5 className='card-title text-center mt-2'>Color 1</h5>
                                    <img src='img/undanganDigital/UndanganDigitalAqiqah/color1.png' className='mx-auto d-block mt-1' style={{width:'125px', minHeight:'175px'}} alt='Undangan Digital aqiqah Amaryllis'/>
                                    <div className='card-body'>
                                        <p className='card-text-harga'>Harga Saat Ini</p>
                                        <p className='card-text-rupiah'>Rp 45.000</p>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6'>
                                                <Link to={'/we-aqiqah/color1'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <a href='https://webew.id/login' className='btn btn-webew-product mt-1 mb-1' aria-label='Produk webew.id'>Buat</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 col-md-4 col-lg-3' id='undanganDigital'>
                                <div className='card' style={{width: '100%',minHeight:'390px'}}>
                                    <h5 className='card-title text-center mt-2'>Color 2</h5>
                                    <img src='img/undanganDigital/UndanganDigitalAqiqah/color2.png' className='mx-auto d-block mt-1' style={{width:'125px', minHeight:'175px'}} alt='Undangan Digital aqiqah Amaryllis'/>
                                    <div className='card-body'>
                                        <p className='card-text-harga'>Harga Saat Ini</p>
                                        <p className='card-text-rupiah'>Rp 45.000</p>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6'>
                                                <Link to={'/we-aqiqah/color2'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <a href='https://webew.id/login' className='btn btn-webew-product mt-1 mb-1' aria-label='Produk webew.id'>Buat</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 col-md-4 col-lg-3' id='undanganDigital'>
                                <div className='card' style={{width: '100%',minHeight:'390px'}}>
                                    <h5 className='card-title text-center mt-2'>Color 3</h5>
                                    <img src='img/undanganDigital/UndanganDigitalAqiqah/color3.png' className='mx-auto d-block mt-1' style={{width:'125px', minHeight:'175px'}} alt='Undangan Digital aqiqah Amaryllis'/>
                                    <div className='card-body'>
                                        <p className='card-text-harga'>Harga Saat Ini</p>
                                        <p className='card-text-rupiah'>Rp 45.000</p>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6'>
                                                <Link to={'/we-aqiqah/color3'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <a href='https://webew.id/login' className='btn btn-webew-product mt-1 mb-1' aria-label='Produk webew.id'>Buat</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 col-md-4 col-lg-3' id='undanganDigital'>
                                <div className='card' style={{width: '100%',minHeight:'390px'}}>
                                    <h5 className='card-title text-center mt-2'>Color 4</h5>
                                    <img src='img/undanganDigital/UndanganDigitalAqiqah/color4.png' className='mx-auto d-block mt-1' style={{width:'125px', minHeight:'175px'}} alt='Undangan Digital aqiqah Amaryllis'/>
                                    <div className='card-body'>
                                        <p className='card-text-harga'>Harga Saat Ini</p>
                                        <p className='card-text-rupiah'>Rp 45.000</p>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6'>
                                                <Link to={'/we-aqiqah/color4'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <a href='https://webew.id/login' className='btn btn-webew-product mt-1 mb-1' aria-label='Produk webew.id'>Buat</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            {/* TUTUP JENIS WE AQIQAH */}
            <img src='https://webew.id/img/bg-lengkung.png' className='img-bg-lengkung-bawah'  style={{width: '100%',minHeight:'360px'}} alt='bg lengkung'/>
            {/* CREDIT IMAGE */}
                <section id='credit-image'>
                    <div className='container mb-4'>
                        <h3 className='judul-credit text-center'>Image By</h3>
                        <div className='row justify-content-center'>
                            <div className='col-8 col-sm-6 col-md-4 col-lg-3 mb-3'>
                                <a href='https://www.freepik.com/free-vector/business-people-arranging-appointment-digital-booking-app_19212427.htm#page=4&query=app&position=22&from_view=author' aria-label='Link mengarah ke pembuat image yaitu Pch-vector Freepik'>
                                    <div className='card' style={{width: '100%',minHeight:'150px'}}>
                                        <img src='https://webew.id/img/credit/19212427_Business people arranging appointment in digital booking app.webp' className='mx-auto d-block'  style={{width: '100%',minHeight:'120px'}} alt='by Pch-vector on Freepik'/>
                                        <div className='card-body'>
                                            <p className='text-center'>Pch-vector on Freepik</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            {/* TUTUP CREDIT IMAGE */}
            {/* NAVBAR BOTTOM */}
                <NavbarBot/>
            {/* TUTUP NAVBAR BOTTOM */}
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP WE AQIQAH
// EKSPOR
export default WeAqiqah