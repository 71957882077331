// IMPORT REACT
import React,{ useState, useEffect } from 'react'
// IMPORT AXIOS
import axios from "axios"
// IMPORT JWT DECODE
import jwt_decode from "jwt-decode"
// IMPORT USE NAVIGATE, LINK
import { useNavigate, Link } from "react-router-dom"
// IMPORT MENU SIDEBAR
import MenuSidebar from '../Layout/MenuSideBar.jsx'
// IMPORT MENU NAVBAR
import MenuNavbar from '../Layout/MenuNavbar.jsx'
// IMPORT MENU NAVBAR BOTTOM
import MenuNavbarBot from '../Layout/MenuNavbarBot.jsx'
// IMPORT MENU DONASI
import MenuDonasi from '../Layout/MenuDonasi.jsx'
// MENU WE AQIQAH
const MenuWeAqiqah = () => { // BUKA KURUNG MENU WE AQIQAH
    // MEMBUAT VARIABEL WE AQIQAH
    const [displayLoader, setDisplayLoader] = useState("")
    const [panggilan, setPanggilan] = useState('')
    // MEMBUAT VARIABEL EXPIRE TOKEN
    const [expire, setExpire] = useState('')
    // MEMBUAT VARIABEL TOKEN
    const [, setToken] = useState('')
    // MEMBUAT VARIABEL NAVIGATE BY USE NAVIGATE
    const navigate = useNavigate()
    // USEEFFECT
    useEffect(()=>{
        refreshToken()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // REFRESH TOKEN
    const refreshToken = async() => {
        try {
            const response = await axios.get('https://server13.webew.id/tokenBE')
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
            setDisplayLoader("none")
        } catch (error) {
            if(error.response){
                navigate("/login", { replace: true })
            }   
        }
    }
    const axiosJWT = axios.create()
    axiosJWT.interceptors.request.use(async(config) => {
        const currentDate = new Date()
        if(expire * 1000 < currentDate.getTime()){
            const response = await axios.get('https://server13.webew.id/tokenBE')
            config.headers.Authorization = `Bearer ${response.data.accessToken}`
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
        }
        return config
    }, (error)=>{
        return Promise.reject(error)
    })    
    // RETURN
    return ( // BUKA KURUNG RETURN
        <div id="wrapper">
            {/* LOADER */}
                <div className="loader-page-bg" style={{display:displayLoader}}>
                    <div className="loader-page"></div>
                </div>
            {/* TUTUP LOADER */}
            {/* DONASI */}
                <MenuDonasi/>
            {/* TUTUP DONASI */}
            {/* SIDEBAR */}
                <MenuSidebar/>
            {/* TUTUP SIDEBAR */}
            {/* KONTEN WRAPPER */}
                <div id="content-wrapper">
                    {/* NAVBAR */}
                        <MenuNavbar/>
                    {/* TUTUP NAVBAR */}
                    {/* KONTEN */}
                        <div className="container-fluid" style={{overflow:"auto",display:"block",width:"100%",height:"88vh"}}>
                            <h4 className="text-center mt-3" style={{fontWeight:"700"}}>We Aqiqah</h4>
                            <section className='mb-5' style={{marginTop:"20px"}}>
                                <h6 className='fw-bold text-center card-text-rupiah'>Note : Tidak ada masa aktif ya, jadi bisa lihat kapan saja</h6>
                                <div className="row mb-5" style={{marginTop:"-40px"}}>
                                    <div className="col-6 col-lg-4 col-xl-3" id="ucapanUltah">
                                        <div className="card" style={{width: "100%",minHeight:"390px"}}>
                                            <h5 className="card-title text-center mt-2">White1</h5>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/white1.png" className="mx-auto d-block mt-1" style={{width:"125px", minHeight:"175px"}} alt="undangan digital aqiqah"/>
                                            <div className="card-body">
                                                <p className="card-text-harga">Harga Saat Ini</p>
                                                <p className="card-text-rupiah">Rp 45.000</p>
                                                <div className="row">
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={'/we-aqiqah/white1'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={`/menu-we-aqiqah-form/${panggilan}/white1`} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Bikin</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-3" id="bikinCV">
                                        <div className="card" style={{width: "100%",minHeight:"390px"}}>
                                            <h5 className="card-title text-center mt-2">White2</h5>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/white2.png" className="mx-auto d-block mt-1" style={{width:"125px", minHeight:"175px"}} alt="undangan digital aqiqah"/>
                                            <div className="card-body">
                                                <p className="card-text-harga">Harga Saat Ini</p>
                                                <p className="card-text-rupiah">Rp 45.000</p>
                                                <div className="row">
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={'/we-aqiqah/white2'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link></div>
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={`/menu-we-aqiqah-form/${panggilan}/white2`} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Bikin</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-3" id="bikinCV">
                                        <div className="card" style={{width: "100%",minHeight:"390px"}}>
                                            <h5 className="card-title text-center mt-2">White3</h5>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/white3.png" className="mx-auto d-block mt-1" style={{width:"125px", minHeight:"175px"}} alt="undangan digital aqiqah"/>
                                            <div className="card-body">
                                                <p className="card-text-harga">Harga Saat Ini</p>
                                                <p className="card-text-rupiah">Rp 45.000</p>
                                                <div className="row">
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={'/we-aqiqah/white3'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link></div>
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={`/menu-we-aqiqah-form/${panggilan}/white3`} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Bikin</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-3" id="undanganDigital">
                                        <div className="card" style={{width: "100%",minHeight:"390px"}}>
                                            <h5 className="card-title text-center mt-2">White4</h5>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/white4.png" className="mx-auto d-block mt-1" style={{width:"125px", minHeight:"175px"}} alt="undangan digital aqiqah"/>
                                            <div className="card-body">
                                                <p className="card-text-harga">Harga Saat Ini</p>
                                                <p className="card-text-rupiah">Rp 45.000</p>
                                                <div className="row">
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={'/we-aqiqah/white4'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link></div>
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={`/menu-we-aqiqah-form/${panggilan}/white4`} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Bikin</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-6 col-lg-4 col-xl-3" id="undanganDigital">
                                        <div className="card" style={{width: "100%",minHeight:"390px"}}>
                                            <h5 className="card-title text-center mt-2">Flower1</h5>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/flower1.png" className="mx-auto d-block mt-1" style={{width:"125px", minHeight:"175px"}} alt="undangan digital aqiqah"/>
                                            <div className="card-body">
                                                <p className="card-text-harga">Harga Saat Ini</p>
                                                <p className="card-text-rupiah">Rp 45.000</p>
                                                <div className="row">
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={'/we-aqiqah/flower1'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link></div>
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={`/menu-we-aqiqah-form/${panggilan}/flower1`} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Bikin</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-3" id="undanganDigital">
                                        <div className="card" style={{width: "100%",minHeight:"390px"}}>
                                            <h5 className="card-title text-center mt-2">Flower2</h5>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/flower2.png" className="mx-auto d-block mt-1" style={{width:"125px", minHeight:"175px"}} alt="undangan digital aqiqah"/>
                                            <div className="card-body">
                                                <p className="card-text-harga">Harga Saat Ini</p>
                                                <p className="card-text-rupiah">Rp 45.000</p>
                                                <div className="row">
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={'/we-aqiqah/flower2'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={`/menu-we-aqiqah-form/${panggilan}/flower2`} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Bikin</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-3" id="undanganDigital">
                                        <div className="card" style={{width: "100%",minHeight:"390px"}}>
                                            <h5 className="card-title text-center mt-2">Flower3</h5>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/flower3.png" className="mx-auto d-block mt-1" style={{width:"125px", minHeight:"175px"}} alt="undangan digital aqiqah"/>
                                            <div className="card-body">
                                                <p className="card-text-harga">Harga Saat Ini</p>
                                                <p className="card-text-rupiah">Rp 45.000</p>
                                                <div className="row">
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={'/we-aqiqah/flower3'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={`/menu-we-aqiqah-form/${panggilan}/flower3`} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Bikin</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-3" id="undanganDigital">
                                        <div className="card" style={{width: "100%",minHeight:"390px"}}>
                                            <h5 className="card-title text-center mt-2">Flower4</h5>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/flower4.png" className="mx-auto d-block mt-1" style={{width:"125px", minHeight:"175px"}} alt="undangan digital aqiqah"/>
                                            <div className="card-body">
                                                <p className="card-text-harga">Harga Saat Ini</p>
                                                <p className="card-text-rupiah">Rp 45.000</p>
                                                <div className="row">
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={'/we-aqiqah/flower4'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={`/menu-we-aqiqah-form/${panggilan}/flower4`} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Bikin</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-6 col-lg-4 col-xl-3" id="undanganDigital">
                                        <div className="card" style={{width: "100%",minHeight:"390px"}}>
                                            <h5 className="card-title text-center mt-2">Color1</h5>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/color1.png" className="mx-auto d-block mt-1" style={{width:"125px", minHeight:"175px"}} alt="undangan digital aqiqah"/>
                                            <div className="card-body">
                                                <p className="card-text-harga">Harga Saat Ini</p>
                                                <p className="card-text-rupiah">Rp 45.000</p>
                                                <div className="row">
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={'/we-aqiqah/color1'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={`/menu-we-aqiqah-form/${panggilan}/color1`} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Bikin</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-3" id="undanganDigital">
                                        <div className="card" style={{width: "100%",minHeight:"390px"}}>
                                            <h5 className="card-title text-center mt-2">Color2</h5>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/color2.png" className="mx-auto d-block mt-1" style={{width:"125px", minHeight:"175px"}} alt="undangan digital aqiqah"/>
                                            <div className="card-body">
                                                <p className="card-text-harga">Harga Saat Ini</p>
                                                <p className="card-text-rupiah">Rp 45.000</p>
                                                <div className="row">
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={'/we-aqiqah/color2'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={`/menu-we-aqiqah-form/${panggilan}/color2`} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Bikin</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-3" id="undanganDigital">
                                        <div className="card" style={{width: "100%",minHeight:"390px"}}>
                                            <h5 className="card-title text-center mt-2">Color3</h5>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/color3.png" className="mx-auto d-block mt-1" style={{width:"125px", minHeight:"175px"}} alt="undangan digital aqiqah"/>
                                            <div className="card-body">
                                                <p className="card-text-harga">Harga Saat Ini</p>
                                                <p className="card-text-rupiah">Rp 45.000</p>
                                                <div className="row">
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={'/we-aqiqah/color3'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={`/menu-we-aqiqah-form/${panggilan}/color3`} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Bikin</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-3 mb-2" id="undanganDigital">
                                        <div className="card" style={{width: "100%",minHeight:"390px"}}>
                                            <h5 className="card-title text-center mt-2">Color4</h5>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/color4.png" className="mx-auto d-block mt-1" style={{width:"125px", minHeight:"175px"}} alt="undangan digital aqiqah"/>
                                            <div className="card-body">
                                                <p className="card-text-harga">Harga Saat Ini</p>
                                                <p className="card-text-rupiah">Rp 45.000</p>
                                                <div className="row">
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={'/we-aqiqah/color4'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <Link to={`/menu-we-aqiqah-form/${panggilan}/color4`} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Bikin</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            {/* NAVBOT */}
                            <MenuNavbarBot/>
                        </div>
                    {/* TUTUP KONTEN */}
                </div>
            {/* TUTUP KONTEN WRAPPER */}
        </div>
    ) // TUTUP KURUNG RETURN
} // TUTUP KUTUNG MENU WE AQIQAH
// EKSPOR
export default MenuWeAqiqah