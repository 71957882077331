// IMPORT REACT
import React,{ useState } from 'react'
// IMPORT MENU SIDEBAR
import MenuSidebar from '../Layout/MenuSideBar.jsx'
// IMPORT MENU NAVBAR
import MenuNavbar from '../Layout/MenuNavbar.jsx'
// IMPORT MENU NAVBAR BOTTOM
import MenuNavbarBot from '../Layout/MenuNavbarBot.jsx'
// IMPORT MENU DONASI
import MenuDonasi from '../Layout/MenuDonasi.jsx'
// MENU WE KHODAM
const MenuWeKhodam = () => { // KURUNG BUKA MENU WE KHODAM
    // MEMBUAT VARIABEL WE KHODAM
    const [nama, setNama] = useState('');
    const frontendWords = ['Singa', 'Macan', 'Harimau', 'Rubah', 'Serigala', 'Sapi', 'Kambing', 'Domba', 'Ular', 'Kalajengking', 'Ulet', 'Kelinci', 'Koala', 'Semut', 'Gajah', 'Badak', 'Gozila', 'Ayam', 'Bebek', 'Soang', 'Kumbang', 'Hiu', 'Paus', 'Kuda', 'Kudanil', 'Monyet', 'Kerbau', 'Banteng', 'Kingkong', 'Belalang', 'Hamster', 'Cicak', 'Kecoa', 'Tuyul', 'Pocong', 'Kelelawar', 'Superman', 'Batman', 'Spiderman', 'Hulk', 'Iguana', 'Kadal', 'Ultraman', 'Kelabang', 'Lalat', 'Belut'];
    const backendWords = ['Mulet', 'Putih', 'Item', 'Merah', 'Kuning', 'Cepak', 'Botak', 'Polkadot', 'Pitak', 'Pemalas', 'Pemarah', 'Pendiem', 'Terbang', 'Melayang', 'Kaki Tiga', 'Kaki Ayam', 'Kaki Kambing', 'Kaki Macan', 'Kaki Lima', 'Ekor Enam', 'Ekor Sembilan', 'Ekor Tiga', 'Mata Ayam', 'Mata Soang', 'Mata Momyet', 'Mata Kucing', 'Gigi Macan', 'Gigi Kelinci', 'Gigi Gingsul', 'Bersayap', 'Berjigong', 'Ekor Sapi', 'Ekor Kucing', 'Ekor Rubah', 'Kaki Kadal', 'Kaki Seribu', 'Kuping Gajah', 'Loncat', 'Kepala Belut', 'Kepala Macan', 'Kepala Kambing', 'Kepala Sapi', 'Kepala Ular', 'Berapi'];
    const [currentWord, setCurrentWord] = useState('');
    const [warning, setWarning] = useState('');
    // PROSES MEMNGACAK KATA
    const displayRandomWord = () => {
        if(nama === ""){
            setWarning("Isi Nama Dulu Ya Kawan !")
        }else{
            setNama("")
            const randomFrontendIndex = Math.floor(Math.random() * frontendWords.length);
            const randomBackendIndex = Math.floor(Math.random() * backendWords.length);
            const randomWord = `${frontendWords[randomFrontendIndex]} ${backendWords[randomBackendIndex]}`;
            setCurrentWord(randomWord);
        }
    }
    // RETURN 
    return ( // KURUNG BUKA RETURN
        <div id="wrapper">
            {/* MODAL DONASI */}
                <MenuDonasi/>
            {/* TUTUP MODAL DONASI */}
            {/* SIDEBAR */}
                <MenuSidebar/>
            {/* TUTUP SIDEBAR */}
            {/* KONTEN WRAPPER */}
                <div id="content-wrapper">
                    {/* NAVBAR */}
                        <MenuNavbar/>
                    {/* TUTUP NAVBAR */}
                    {/* KONTEN */}
                        <div className="container-fluid" style={{overflow:"auto",display:"block",width:"100%",height:"88vh"}}>
                            <h4 className="text-center mt-3" style={{fontWeight:"700"}}>Cek Khodam Kalian Disini</h4>
                            <h6 className="text-center mt-3" style={{fontWeight:"600"}}>Jika kalian penasaran didalam badan kalian ada khodam apa, cek disini. Hasil khodam sesuai mood sistem, dan untuk hiburan saja ya</h6>
                            <section style={{marginTop:"25px"}}>
                                <div className='row justify-content-center'>
                                    <div className="col-12 col-md-6">
                                        <div className="box-kodam py-4 px-5 mt-4">
                                            <h4 className='text-center fw-bold pb-4'>Masukan Nama Lalu Klik Tombol</h4>
                                            <h6 className='text-center fw-semiBold' style={{color:"red",marginTop:"-12px",marginBottom:"-10px"}}>{warning}</h6>
                                            <input type="text" id="nama" className="form-control form my-3" value={nama} onChange={(e)=>setNama(e.target.value)} maxLength="40" placeholder='Masukan Nama' required/>
                                            <button className='btn btn-webew-product my-3' onClick={displayRandomWord}>Klik Untuk Cek Khodam</button>
                                            <h5 className='text-center fw-semibold mt-2 mb-3'>Khodam yang kalian miliki adalah :</h5>
                                            <h5 className='text-center fw-bold mt-3'>{currentWord}</h5>
                                        </div>
                                    </div>
                                </div>
                                {/* NAVBOT */}
                                <MenuNavbarBot/>
                            </section>
                        </div>
                    {/* TUTUP KONTEN */}
                </div>
            {/* TUTUP KONTEN WRAPPER */}
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP MENU WE KHODAM
// EKSPOR
export default MenuWeKhodam