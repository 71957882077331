// IMPORT REACT
import React,{ useRef, useState, useEffect } from 'react'
// IMPORT AXIOS
import axios from "axios"
// IMPORT JWT DECODE
import jwt_decode from "jwt-decode"
// IMPORT USE PARAMS, USE NAVIGATE, LINK
import { useParams, useNavigate, Link } from "react-router-dom"
// IMPORT HTML 2 CANVAS
import html2canvas from 'html2canvas'
// IMPORT JS PDF
import jsPDF from 'jspdf'
// IMPORT MENU SIDEBAR
import MenuSidebar from '../../Layout/MenuSideBar.jsx'
// IMPORT MENU NAVBAR
import MenuNavbar from '../../Layout/MenuNavbar.jsx'
// IMPORT MENU NAVBAR BOTTOM
import MenuNavbarBot from '../../Layout/MenuNavbarBot.jsx'
// IMPORT MENU DONASI
import MenuDonasi from '../../Layout/MenuDonasi.jsx'
// MENU WE PERNIKAHAN HASIL
const MenuWePernikahanHasil = () => { // KURUNG BUKA WE PERNIKAHAN HASIL
    // MEMBUAT VARIABEL MENU WE PERNIKAHAN HASIL
    const { panggilanParams } = useParams();
    const { jenisWePernikahanParams } = useParams();
    const [panggilan, setPanggilan] = useState('')
    const [jenisUdp, setJenisUdp] = useState('')
    const [name, setName] = useState('')
    const [total,] = useState('45000')
    const [order_id, setOrder_id] = useState('')
    const [tokenTransaksi, setTokenTransaksi] = useState('')
    const [kodeOrder, setKodeOrder] = useState('')
    const [apiWhatsapp, setApiWhatsapp] = useState('')
    const [namaPanggilPria, setNamaPanggilPria] = useState('')
    const [namaPanggilWanita, setNamaPanggilWanita] = useState('')
    const [dataUcapan, setDataUcapan] = useState([])
    const [salinLink, setSalinLink] = useState('')
    const [displayPembayaran, setDisplayPembayaran] = useState('')
    const [displayLoader, setDisplayLoader] = useState('')
    const [display, setDisplay] = useState('')
    // MEMBUAT VARIABEL EXPIRE TOKEN
    const [expire, setExpire] = useState('')
    // MEMBUAT VARIABEL INPUTREF BY USEREF
    const inputRef = useRef(null);
    // MEMBUAT VARIABEL TOKEN
    const [, setToken] = useState('')
    // MEMBUAT VARIABEL NAVIGATE BY USE NAVIGATE
    const navigate = useNavigate()
    // USEEFFECT
    useEffect(()=>{
        refreshToken()
        selectWePernikahan()
        selectUcapanWePernikahan()
        selectTransaksi()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // REFRESH TOKEN
    const refreshToken = async() => {
        try {
            const response = await axios.get('https://server13.webew.id/tokenBE')
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setName(decoded.name)
            setExpire(decoded.exp)
            setDisplayPembayaran("none")
            setDisplayLoader("none")
        } catch (error) {
            if(error.response){
                navigate("/login", { replace: true })
            }   
        }
    }
    const axiosJWT = axios.create()
    axiosJWT.interceptors.request.use(async(config) => {
        const currentDate = new Date()
        if(expire * 1000 < currentDate.getTime()){
            const response = await axios.get('https://server13.webew.id/tokenBE')
            config.headers.Authorization = `Bearer ${response.data.accessToken}`
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
            setName(decoded.name)
        }
        return config
    }, (error)=>{
        return Promise.reject(error)
    })
    // PROSES MENAMPILKAN WE PERNIKAHAN BERDASARKAN PANGGILAN BY PARAMS
    const selectWePernikahan = async() =>{
        try{
            const response = await axios.get(`https://server13.webew.id/selectWePernikahanBE/${panggilanParams}`)
            setNamaPanggilPria(response.data.namaPanggilPria)
            setNamaPanggilWanita(response.data.namaPanggilWanita)
            setApiWhatsapp("https://api.whatsapp.com/send?text=https://webew.id/undangan-digital-pernikahan/" + response.data.salinLink + "/Bapak%20Ibu")
            setSalinLink("https://webew.id/undangan-digital-pernikahan/"+response.data.salinLink + "/Bapak%20Ibu")
            setJenisUdp(response.data.jenisUndanganDigitalPernikahan)
            const Seconds = new Date().getSeconds()
            const result = Math.random().toString(36).substring(2,12);
            setOrder_id(response.data.jenisUndanganDigitalPernikahan + result + Seconds)
        }catch(error){
            console.log(error)
        }
    }
    // PROSES MENAMPILKAN UCAPAN WE PERNIKAHAN BERDASARKAN PANGGILAN BY PARAMS
    const selectUcapanWePernikahan = async() =>{
        try{
            const response = await axios.get(`https://server13.webew.id/selectUcapanWePernikahanBE/${panggilanParams}`)
            setDataUcapan(response.data)
        }catch(error){
            console.log(error)
        }
    }
    // PROSES TRANSAKSI
    const transaksi = async (e) => {
        e.preventDefault()
        try{
            const data = {
                nama: name,
                order_id: order_id,
                total: total,
                panggilan: panggilanParams
            }
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            }
            const response = await axios.post("https://server13.webew.id/prosesTransaksiBE",data,config)
            setTokenTransaksi(response.data.tokenTransaksi)
        }catch(error){
            console.log(error)
        }
    }
    // PROSES MENYIMPAN TRANSAKSI KE DB
    const transaksiBerhasil = async (e) => {
        e.preventDefault()
        try{
            setDisplayLoader("")
            await axios.post("https://server13.webew.id/transaksiBerhasilBE",{
                nama: name,
                order_id: order_id,
                total: total,
                panggilan: panggilanParams
            })
            window.location.reload()
        }catch(error){
            console.log(error)
        }
    }
    // USEEFFECT TRANSAKSI
    useEffect(()=>{
        if(tokenTransaksi){
            window.snap.pay(tokenTransaksi, {
                onSuccess: (result) => {
                    setDisplayPembayaran("")
                    localStorage.setItem("Pembayaran", JSON.stringify(result))
                    setTokenTransaksi("")
                },
                onPending: (result) => {
                    window.location.reload()
                    localStorage.setItem("Pembayaran", JSON.stringify(result))
                    setTokenTransaksi("")
                },
                onError: (error) => {
                    window.location.reload()
                    console.log(error)
                    setTokenTransaksi("")
                },
                onClose: () => {
                    window.location.reload()
                    console.log("Anda belum menyelesaikan pembayaran")
                    setTokenTransaksi("")
                },
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenTransaksi])
    useEffect(()=>{
        const midtransUrl = "https://app.midtrans.com/snap/snap.js"
        let scriptTag = document.createElement("script")
        scriptTag.src = midtransUrl
        const midtransClientKey = "Mid-client-D6LOR1Eecoo9s-wv"
        scriptTag.setAttribute("data-client-key", midtransClientKey)
        document.body.appendChild(scriptTag)
        return() => {
            document.body.removeChild(scriptTag)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // PROSES MENAMPILKAN DATA TRANSAKSI BERDASARKAN PANGGILAN BY PARAMS
    const selectTransaksi = async() =>{
        try{
            const response = await axios.get(`https://server13.webew.id/selectTransaksiBE/${panggilanParams}`)
            let kodeOrderId = response.data.order_id
            setKodeOrder(kodeOrderId.substring(0, 3))
        }catch(error){
            console.log(error)
        }
    }
    // PROSES MENYALIN TEKS / LINK
    function copyToClipboard(e) {
        inputRef.current.select()
        document.execCommand('copy')
        e.target.focus()
    }
    // PROSES MENUTUP MODAL
    const tutup = () => {
        setDisplay("none")
    }
    // PROSES DOWNLOAD PDF
    const downloadPdfDocument = () => {
        const input = document.getElementById("wrapperUcapan")
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png')
                const pdf = new jsPDF('l', 'mm', 'a4')
                var width = pdf.internal.pageSize.getWidth();
                var height = pdf.internal.pageSize.getHeight();
                pdf.addImage(imgData, 'PNG', 0, 0, width, height)
                pdf.save(`Ucapan pernikahan ${namaPanggilPria} dan ${namaPanggilWanita}.pdf`)
            })
    }
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div id="wrapper">
            {/* LOADER */}
                <div className="loader-page-bg" style={{display:displayLoader}}>
                    <div className="loader-page"></div>
                </div>
            {/* TUTUP LOADER */}
            {/* MODAL */}
                {/* MODAL UCAPAN*/}
                    <div className="modal fade" id="staticBackdropRiwayatUDPernikahan" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="btn btn-logout mx-auto py-1 px-4" data-bs-dismiss="modal" aria-label="Tutup">Tutup</button>
                                </div>
                                <div className="modal-body">
                                    <div id='ucapan' className="row justify-content-center">
                                        <div className="col-12 mt-4">
                                            <div className="box p-4" style={{marginTop:"-20px",marginBottom:"5px",height:"auto"}}>
                                                <div id='wrapperUcapan'>
                                                    <h6 className='text-center fw-bold mb-4'>Daftar Orang Yang Memberikan Ucapan</h6>
                                                    <div className="box p-2" style={{width: "100%",maxHeight:"250px",minHeight:"250px",overflow:"auto"}}>
                                                        <table>
                                                            <thead>
                                                                <tr style={{borderBottom:"2px solid grey",borderTop:"2px solid grey"}}>
                                                                    <td className='fw-bold pb-2' style={{border:"1px solid grey"}}>No</td>
                                                                    <td className='fw-bold pb-2' style={{border:"1px solid grey"}}>Nama</td>
                                                                    <td className='fw-bold pb-2' style={{border:"1px solid grey"}}>Status</td>
                                                                    <td className='fw-bold pb-2' style={{border:"1px solid grey"}}>Ucapan</td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {dataUcapan.map((user,index)=>(
                                                                    <tr key={dataUcapan.id} style={{borderBottom:"2px solid grey"}}>
                                                                        <td className='p-2' style={{fontSize:"10pt",width:"5%",border:"1px solid grey"}}>{index + 1}</td>
                                                                        <td className='p-2' style={{fontSize:"10pt",width:"35%",border:"1px solid grey"}}>{user.nama}</td>
                                                                        <td className='p-2' style={{fontSize:"10pt",width:"10%",border:"1px solid grey"}}>{user.status}</td>
                                                                        <td className='p-2' style={{fontSize:"10pt",width:"50%",textAlign:"left",border:"1px solid grey"}}>{user.ucapan}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-md-8">
                                                        <button onClick={downloadPdfDocument} className="btn btn-webew-product mt-4">Cetak PDF</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                {/* TUTUP MODAL UCAPAN */}
                {/* MODAL DONASI */}
                    <MenuDonasi/>
                {/* TUTUP MODAL DONASI */}
                {/* MODAL MESSAGE*/}
                    <div style={{position:"fixed",zIndex:"9999",backgroundColor:"#0000004c",height:"100vh",width:"100%",display:display}}>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-6">
                                    <div style={{backgroundColor:"white", width:"100%", padding:"20px",borderRadius:"20px",marginTop:"15px",boxShadow:"-10px 10px 25px -15px #7f7f7f"}}>
                                        {jenisUdp !== jenisWePernikahanParams
                                            ?<h6 style={{textAlign:"center",color:"red",fontWeight:"700"}}>Maaf ya kamu belum isi form data, silahkan isi form data dulu ya</h6> :<h6 className='card-text-rupiah mb-2' style={{textAlign:"center",fontWeight:"700"}}>Hore undangan digital Pernikahan tema {jenisUdp} sudah jadi<br /><p style={{margin:"20px 0 0 0",fontSize:"12pt",fontWeight:"500"}}>Silahkan lakukan proses pembayaran, jika sudah nanti tekan salin link lalu bagikan ke orang-orang ya, jangan lupa Ganti tulisan "/Bapak%20Ibu" dengan nama tamu di link</p></h6>
                                        }
                                        {jenisUdp !== jenisWePernikahanParams
                                            ?<Link to={`/menu-we-pernikahan-form/${panggilan}/${jenisWePernikahanParams}`} className="btn btn-webew-home" aria-label="Kembali webew.id" style={{marginTop:"18px"}}>Oke Siap</Link> :<button type="button" className="btn btn-webew-product" style={{marginTop:"18px"}} onClick={tutup}>Oke Siap</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{position:"fixed",zIndex:"9999",backgroundColor:"#0000004c",height:"100vh",width:"100%",display:displayPembayaran}}>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-6">
                                    <div style={{backgroundColor:"white", width:"100%", padding:"20px",borderRadius:"20px",marginTop:"15px",boxShadow:"-10px 10px 25px -15px #7f7f7f"}}>
                                        <h6 className='card-text-rupiah mb-2' style={{textAlign:"center",fontWeight:"700"}}>Hore Pembayaran Berhasil<br /><p style={{margin:"20px 0 0 0",fontSize:"12pt",fontWeight:"500"}}>Silahkan tekan tombol oke, jika sudah nanti tekan salin link lalu bagikan ke orang-orang ya, jangan lupa Ganti tulisan "/Bapak%20Ibu" dengan nama tamu di link, terimakasih<br /></p></h6>
                                        <form onSubmit={transaksiBerhasil}>
                                            <input type="text" className="form-disable" value={name} readOnly/>
                                            <input type="text" className="form-disable" value={order_id} readOnly/>
                                            <input type="text" className="form-disable" value={total} readOnly/>
                                            <input type="text" className="form-disable" value={panggilanParams} readOnly/>
                                            <input type="submit" className="btn btn-webew-product" value="Okeee" style={{marginTop:"18px"}}/>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                {/* TUTUP MODAL MESSAGE */}
            {/* TUTUP MODAL */}
            {/* SIDEBAR */}
                <MenuSidebar/>
            {/* TUTUP SIDEBAR */}
            {/* KONTEN WRAPPER */}
                <div id="content-wrapper">
                    {/* NAVBAR */}
                        <MenuNavbar/>
                    {/* TUTUP NAVBAR */}
                    {/* KONTEN */}
                        <div className="container-fluid" style={{overflow:"auto",display:"block",width:"100%",height:"88vh"}}>
                            <section id="home">
                                <div className="container mb-5">
                                    <div className="row">
                                        <div className="col-12 col-lg-6 px-1 mt-2">
                                            <h2 className="title-webew-home">Salin Link We Pernikahan</h2>
                                            {kodeOrder === jenisUdp
                                                ?<p className="text-webew-home">Silahkan klik tombol salin link, lalu bagikan ke saudara atau lainnya yaa, bisa juga bagikan lewat WhatsApp</p>
                                                :<p className="text-webew-home">Silahkan klik tombol beli, jika sudah maka kamu bisa mendapatkan link yang berisikan undangan digital aqiqah</p>
                                            }
                                            <div className="row justify-content-center">
                                                <div className="col-6 col-md-4">
                                                    <Link to={`/menu-we-pernikahan-form/${panggilanParams}/${jenisWePernikahanParams}`} className="btn btn-webew-home" aria-label="Kembali webew.id">Kembali</Link>
                                                </div>
                                                <div className="col-6 col-md-4 mb-4">
                                                    <button className="btn btn-webew-home" data-bs-toggle="modal" data-bs-target="#staticBackdropRiwayatUDPernikahan">Ucapan</button>
                                                </div>
                                                <div className="col-8 col-md-4">
                                                    {kodeOrder === jenisUdp
                                                    ?<Link to={`/menu-we-pernikahan-hasil/${panggilan}/${jenisWePernikahanParams}`} className="btn btn-webew-home"><i class="bi bi-check-lg" style={{color:"white"}}></i> Sudah Dibeli</Link> 
                                                    :<form onSubmit={transaksi}>
                                                            <input type="text" className="form-disable" value={name} readOnly/>
                                                            <input type="text" className="form-disable" value={order_id} readOnly/>
                                                            <input type="text" className="form-disable" value={total} readOnly/>
                                                            <input type="text" className="form-disable" value={panggilanParams} readOnly/>
                                                            <input type="submit" className="btn btn-webew-home" value="Beli Rp45.000"/>
                                                        </form>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6 mb-3">
                                            <div className="box p-4" style={{width: "100%",minHeight:"280px", marginTop:"100px"}}>
                                                <h4 className='text-center'>Hai {namaPanggilPria} dan {namaPanggilWanita}</h4>
                                                {kodeOrder === jenisUdp
                                                    ?<div className="row">
                                                        <div className="col-12 col-sm-7 mt-2">
                                                            <input type="text" id="link" className="form-control form mt-3" ref={inputRef} value={salinLink} readOnly/>
                                                        </div>
                                                        <div className="col-12 col-sm-5 mt-2">
                                                            <button type="button" className="btn btn-webew-product" style={{marginTop:"16.5px"}} onClick={copyToClipboard}><i class="bi bi-unlock-fill" style={{color:"white"}}></i> Salin Link</button>
                                                        </div>
                                                        <div className="col-12 mt-2">
                                                            <a href={apiWhatsapp} className="btn btn-webew-product mt-4" style={{height:"auto"}}><i class="bi bi-unlock-fill" style={{color:"white"}}></i> Atau bagikan lewat WhatsApp</a>
                                                        </div>
                                                    </div>
                                                    :<div className="row">
                                                        <div className="col-12 col-sm-7 mt-2">
                                                            <input type="text" id="link" className="form-control form mt-3" readOnly/>
                                                        </div>
                                                        <div className="col-12 col-sm-5 mt-2">
                                                            <button type="button" className="btn btn-webew-product" style={{marginTop:"16.5px"}}><i class="bi bi-lock-fill" style={{color:"white"}}></i> Salin Link</button>
                                                        </div>
                                                        <div className="col-12 mt-2">
                                                            <Link to={`/menu-we-pernikahan-hasil/${panggilan}/${jenisWePernikahanParams}`} className="btn btn-webew-product mt-4" style={{height:"auto"}}><i class="bi bi-lock-fill" style={{color:"white"}}></i> Atau bagikan lewat WhatsApp</Link>
                                                        </div>
                                                    </div>
                                                }
                                                <h6 className='text-center mt-4'>Kami dari webew.id mengucapkan selamat ya dan semoga acaranya lancar dan dimudahkan</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* NAVBOT */}
                                <MenuNavbarBot/>
                            </section>
                        </div>
                    {/* TUTUP KONTEN */}
                </div>
            {/* TUTUP KONTEN WRAPPER */}
        </div>
    ) // TUTUP KURUNG RETURN
} // TUTUP KURUNG MENU WE PERNIKAHAN HASIL
// EKSPOR
export default MenuWePernikahanHasil