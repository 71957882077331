// IMPORT REACT
import React,{ useState, useEffect } from 'react'
// IMPORT AXIOS
import axios from "axios"
// IMPORT JWT DECODE
import jwt_decode from "jwt-decode"
// IMPORT USE PARAMS, USE NAVIGATE LINK
import { useParams, useNavigate, Link } from "react-router-dom"
// IMPORT MENU SIDEBAR
import MenuSidebar from '../../Layout/MenuSideBar.jsx'
// IMPORT MENU NAVBAR
import MenuNavbar from '../../Layout/MenuNavbar.jsx'
// IMPORT MENU NAVBAR BOTTOM
import MenuNavbarBot from '../../Layout/MenuNavbarBot.jsx'
// IMPORT MENU DONASI
import MenuDonasi from '../../Layout/MenuDonasi.jsx'
// MENU WE WEBSITE DOWNLOAD
const MenuWeWebsiteDownload = () => { // KURUNG BUKA MENU WE WEBSITE DOWNLOAD
    // MEMBUAT VARIABEL MENU WE WEBSITE DOWNLOAD
    const { jenisWeWebsiteParams } = useParams();
    const { panggilanParams } = useParams();
    const [name, setName] = useState('')
    const [kodeOrder, setKodeOrder] = useState('')
    const [total,] = useState('5000')
    const [order_id, setOrder_id] = useState('')
    const [tokenTransaksi, setTokenTransaksi] = useState('')
    const [linkDownload, setLinkDownload] = useState("")
    const [imageWeb, setImageWeb] = useState("")
    const [displayPembayaran, setDisplayPembayaran] = useState('')
    const [displayLoader, setDisplayLoader] = useState('')
    const [panggilan, setPanggilan] = useState('')
    // MEMBUAT VARIABEL EXPIRE TOKEN
    const [expire, setExpire] = useState('')
    // MEMBUAT VARIABEL TOKEN
    const [, setToken] = useState('')
    // MEMBUAT VARIABEL NAVIGATE BY USENAVIGATE
    const navigate = useNavigate()
    // USEEFECT
    useEffect(()=>{
        refreshToken()
        selectTransaksi()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // REFRESH TOKEN
    const refreshToken = async() => {
        try {
            const response = await axios.get('https://server13.webew.id/tokenBE')
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setName(decoded.name)
            setExpire(decoded.exp)
            setDisplayPembayaran("none")
            setDisplayLoader("none")
            const Seconds = new Date().getSeconds()
            const result = Math.random().toString(36).substring(2,12);
            setOrder_id(jenisWeWebsiteParams + result + Seconds)
            if(jenisWeWebsiteParams === "WP1"){
                setImageWeb("https://webew.id/img/desainWebsite/ImgWebsiteG/PortoG1.webp")
                setLinkDownload("https://webew.id/img/desainWebsite/AssetWebsiteG/webPortofolioG1.zip")
            }else if(jenisWeWebsiteParams === "WP2"){
                setImageWeb("https://webew.id/img/desainWebsite/ImgWebsiteG/PortoG2.webp")
                setLinkDownload("https://webew.id/img/desainWebsite/AssetWebsiteG/webPortofolioG2.zip")
            }else if(jenisWeWebsiteParams === "WP3"){
                setImageWeb("https://webew.id/img/desainWebsite/ImgWebsiteG/PortoG3.webp")
                setLinkDownload("https://webew.id/img/desainWebsite/AssetWebsiteG/webPortofolioG3.zip")
            }else if(jenisWeWebsiteParams === "WP4"){
                setImageWeb("https://webew.id/img/desainWebsite/ImgWebsiteG/PortoG4.webp")
                setLinkDownload("https://webew.id/img/desainWebsite/AssetWebsiteG/webPortofolioG4.zip")
            }
        } catch (error) {
            if(error.response){
                navigate("/login", { replace: true })
            }   
        }
    }
    const axiosJWT = axios.create()
    axiosJWT.interceptors.request.use(async(config) => {
        const currentDate = new Date()
        if(expire * 1000 < currentDate.getTime()){
            const response = await axios.get('https://server13.webew.id/tokenBE')
            config.headers.Authorization = `Bearer ${response.data.accessToken}`
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setName(decoded.name)
            setExpire(decoded.exp)
        }
        return config
    }, (error)=>{
        return Promise.reject(error)
    })
    // PROSES TRANSAKSI
    const transaksi = async (e) => {
        e.preventDefault()
        try{
            const data = {
                nama: name,
                order_id: order_id,
                total: total,
                panggilan: panggilan
            }
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            }
            const response = await axios.post("https://server13.webew.id/prosesTransaksiBE",data,config)
            setTokenTransaksi(response.data.tokenTransaksi)
        }catch(error){
            console.log(error)
        }
    }
    // PROSES MENYIMPAN TRANSAKSI KE DB
    const transaksiBerhasil = async (e) => {
        e.preventDefault()
        try{
            setDisplayLoader("")
            await axios.post("https://server13.webew.id/transaksiBerhasilBE",{
                nama: name,
                order_id: order_id,
                total: total,
                panggilan: panggilan
            })
            window.location.reload()
        }catch(error){
            console.log(error)
        }
    }
    // USEEFFECT TRANSAKSI
    useEffect(()=>{
        if(tokenTransaksi){
            window.snap.pay(tokenTransaksi, {
                onSuccess: (result) => {
                    setDisplayPembayaran("")
                    localStorage.setItem("Pembayaran", JSON.stringify(result))
                    setTokenTransaksi("")
                },
                onPending: (result) => {
                    window.location.reload()
                    localStorage.setItem("Pembayaran", JSON.stringify(result))
                    setTokenTransaksi("")
                },
                onError: (error) => {
                    window.location.reload()
                    console.log(error)
                    setTokenTransaksi("")
                },
                onClose: () => {
                    window.location.reload()
                    console.log("Anda belum menyelesaikan pembayaran")
                    setTokenTransaksi("")
                },
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenTransaksi])
    useEffect(()=>{
        const midtransUrl = "https://app.midtrans.com/snap/snap.js"
        let scriptTag = document.createElement("script")
        scriptTag.src = midtransUrl
        const midtransClientKey = "Mid-client-D6LOR1Eecoo9s-wv"
        scriptTag.setAttribute("data-client-key", midtransClientKey)
        document.body.appendChild(scriptTag)
        return() => {
            document.body.removeChild(scriptTag)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // PROSES MENAMPILKAN DATA TRANSAKSI BERDASARKAN PANGGILAN BY PARAMS
    const selectTransaksi = async() =>{
        try{
            const response = await axios.get(`https://server13.webew.id/selectTransaksiBE/${panggilanParams}`)
            let kodeOrderId = response.data.order_id
            setKodeOrder(kodeOrderId.substring(0, 3))
        }catch(error){
            console.log(error)
        }
    }
    const Style = {
        imgWeb:{
            backgroundColor: "black",
            maxHeight: "430px",
            overflow: "auto",
            borderRadius: "10px",
        }
    }
    // RETURN
    return ( // BUKA KURUNG RETURN
        <div id="wrapper">
            {/* LOADER */}
                <div className="loader-page-bg" style={{display:displayLoader}}>
                    <div className="loader-page"></div>
                </div>
            {/* TUTUP LOADER */}
            {/* MODAL DONASI */}
                <MenuDonasi/>
            {/* TUTUP MODAL DONASI */}
            {/* MODAL MESSAGE*/}
                <div style={{position:"fixed",zIndex:"9999",backgroundColor:"#0000004c",height:"100vh",width:"100%",display:displayPembayaran}}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-6">
                                <div style={{backgroundColor:"white", width:"100%", padding:"20px",borderRadius:"20px",marginTop:"15px",boxShadow:"-10px 10px 25px -15px #7f7f7f"}}>
                                    <h6 className='card-text-rupiah mb-2' style={{textAlign:"center",fontWeight:"700"}}>Hore Pembayaran Berhasil<br /><p style={{margin:"20px 0 0 0",fontSize:"12pt",fontWeight:"500"}}>Silahkan tekan tombol oke, jika sudah lalu tekan tombol "Unduh" untuk mengunduh Desain Website nya, terimakasih<br /></p></h6>
                                    <form onSubmit={transaksiBerhasil}>
                                        <input type="text" className="form-disable" value={name} readOnly/>
                                        <input type="text" className="form-disable" value={order_id} readOnly/>
                                        <input type="text" className="form-disable" value={total} readOnly/>
                                        <input type="text" className="form-disable" value={panggilan} readOnly/>
                                        <input type="submit" className="btn btn-webew-product" value="Okeee" style={{marginTop:"18px"}}/>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            {/* TUTUP MODAL MESSAGE */}
            {/* SIDEBAR */}
                <MenuSidebar/>
            {/* TUTUP SIDEBAR */}
            {/* KONTEN WRAPPER */}
                <div id="content-wrapper">
                    {/* NAVBAR */}
                        <MenuNavbar/>
                    {/* TUTUP NAVBAR */}
                    {/* KONTEN */}
                        <div className="container-fluid" style={{overflow:"auto",display:"block",width:"100%",height:"88vh"}}>
                            <section>
                                <div className="row mb-5">
                                    <div className="col-12 col-xl-6 px-3">
                                        <h3 className="title-webew-home">Website Tema {jenisWeWebsiteParams}</h3>
                                        <p className="text-webew-home">Website portofolio yang memiliki 1 halaman dan sudah responsive di segala device dan juga terdapat Animate On Scroll.</p>
                                        <div className="row justify-content-center">
                                            <div className="col-6 col-md-4">
                                                <Link to={`/menu-we-website/${panggilan}`} className="btn btn-webew-home" aria-label="Kembali webew.id">Kembali</Link>
                                            </div>
                                            <div className="col-6 col-md-4 mb-4">
                                                {kodeOrder === "WP1"
                                                    ?<Link to={`menu-we-website-download/${panggilan}`} className="btn btn-webew-home"><i class="bi bi-check-lg" style={{color:"white"}}></i> Sudah Dibeli</Link> 
                                                    :<form onSubmit={transaksi}>
                                                        <input type="text" className="form-disable" value={name} readOnly/>
                                                        <input type="text" className="form-disable" value={order_id} readOnly/>
                                                        <input type="text" className="form-disable" value={total} readOnly/>
                                                        <input type="text" className="form-disable" value={panggilan} readOnly/>
                                                        <input type="submit" className="btn btn-webew-home" value="Beli Rp5.000"/>
                                                    </form>
                                                }
                                            </div>
                                            <div className="col-6 col-md-4 mb-4">
                                                {kodeOrder === jenisWeWebsiteParams
                                                    ?<a href={linkDownload} className="btn btn-webew-home"><i class="bi bi-unlock-fill" style={{color:"white"}}></i> Unduh</a>
                                                    :<Link to={`/menu-we-website-download/${panggilan}`} className="btn btn-webew-home"><i class="bi bi-lock-fill" style={{color:"white"}}></i> Unduh</Link> 
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-6 mt-4"style={Style.imgWeb}>
                                        <img src={imageWeb} className="img-fluid mx-auto d-block" width="480px" alt="website porto g1"/>
                                    </div>
                                </div>
                                {/* NAVBOT */}
                                <MenuNavbarBot/>
                            </section>
                        </div>
                    {/* TUTUP KONTEN */}
                </div>
            {/* TUTUP KONTEN WRAPPER */}
        </div>
    ) // TUTUP KURUNG RETURN
} // TUTUP KURUNG MENU WE WEBSITE DOWNLOAD
// EKSPOR
export default MenuWeWebsiteDownload