// IMPORT REACT
import React,{ useState, useEffect } from 'react'
// IMPORT AXIOS
import axios from "axios"
// IMPORT JWT DECODE
import jwt_decode from "jwt-decode"
// IMPORT USE NAVIGATE, LINK
import { useNavigate, Link } from "react-router-dom"
// IMPORT MENU SIDEBAR
import MenuSidebar from '../Layout/MenuSideBar.jsx'
// IMPORT MENU NAVBAR
import MenuNavbar from '../Layout/MenuNavbar.jsx'
// IMPORT MENU NAVBAR BOTTOM
import MenuNavbarBot from '../Layout/MenuNavbarBot.jsx'
// IMPORT MENU DONASI
import MenuDonasi from '../Layout/MenuDonasi.jsx'
// MENU WE WEBSITE
const MenuWeWebsite = () => { // KURUNG BUKA MENU WE WEBSITE
    // MEMBUAT VARIABEL WE WEBSITE
    const [displayLoader, setDisplayLoader] = useState("")
    const [panggilan, setPanggilan] = useState('')
    // MEMBUAT VARIABEL EXPIRE TOKEN
    const [expire, setExpire] = useState('')
    // MEMBUAT TOKEN
    const [, setToken] = useState('')
    // MEMBUAT VARIABEL NAVIGATE BY USE NAVIGATE
    const navigate = useNavigate()
    // USEEFFECT
    useEffect(()=>{
        refreshToken()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // REFRESH TOKEN
    const refreshToken = async() => {
        try {
            const response = await axios.get('https://server13.webew.id/tokenBE')
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
            setDisplayLoader("none")
        } catch (error) {
            if(error.response){
                navigate("/login", { replace: true })
            }   
        }
    }
    const axiosJWT = axios.create()
    axiosJWT.interceptors.request.use(async(config) => {
        const currentDate = new Date()
        if(expire * 1000 < currentDate.getTime()){
            const response = await axios.get('https://server13.webew.id/tokenBE')
            config.headers.Authorization = `Bearer ${response.data.accessToken}`
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
        }
        return config
    }, (error)=>{
        return Promise.reject(error)
    })
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div id="wrapper">
            {/* LOADER */}
                <div className="loader-page-bg" style={{display:displayLoader}}>
                    <div className="loader-page"></div>
                </div>
            {/* TUTUP LOADER */}
            {/* MODAL DONASI */}
                <MenuDonasi/>
            {/* TUTUP MODAL DONASI */}
            {/* SIDEBAR */}
                <MenuSidebar/>
            {/* TUTUP SIDEBAR */}
            {/* KONTEN WRAPPER */}
                <div id="content-wrapper">
                    {/* NAVBAR */}
                        <MenuNavbar/>
                    {/* TUTUP NAVBAR */}
                    {/* KONTEN */}
                        <div className="container-fluid" style={{overflow:"auto",display:"block",width:"100%",height:"88vh"}}>
                            <h4 className="text-center mt-3" style={{fontWeight:"700"}}>Desain Website</h4>
                            <section style={{marginTop:"-40px"}}>
                                <div className="row mb-5 pb-5">
                                    <div className="col-6 col-lg-4 col-xl-3" id="website">
                                        <div className="card" style={{width: "100%",minHeight:"390px"}}>
                                            <h5 className="card-title text-center mt-2">Web Porto G1</h5>
                                            <img src="https://webew.id/img/desainWebsite/web-porto-g1.png" className="card-img-top mx-auto d-block" style={{width:"130px",minHeight:"175px"}} alt="Website portofolio G1"/>
                                            <div className="card-body">
                                                <table className="mb-2 mt-1">
                                                    <tbody>
                                                        <tr>
                                                            <td width="33,3%"><img src="https://webew.id/img/desainWebsite/HTML 5.png" className="img-fluid mx-auto d-block" style={{width:"40px",minHeight:"40px"}} alt="Logo HTML 5"/></td>
                                                            <td width="33,3%"><img src="https://webew.id/img/desainWebsite/css3-logo-png-transparent.png" className="img-fluid mx-auto d-block" style={{width:"40px",minHeight:"40px"}} alt="Logo CSS 3"/></td>
                                                            <td width="33,4%"><img src="https://webew.id/img/desainWebsite/bootstrap-logo.png" className="img-fluid mx-auto d-block" style={{width:"40px",minHeight:"40px"}} alt="Logo Bootstrap 5"/></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <p className="card-text-harga">Harga Saat Ini</p>
                                                <p className="card-text-rupiah">Rp 5.000</p>
                                                <Link to={`/menu-we-website-download/${panggilan}/WP1`} className="btn btn-webew-product mx-auto d-block">Lihat</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-3" id="undanganDigital">
                                        <div className="card" style={{width: "100%",minHeight:"390px"}}>
                                            <h5 className="card-title text-center mt-2">Web Porto G2</h5>
                                            <img src="https://webew.id/img/desainWebsite/web-porto-g2.png" className="card-img-top mx-auto d-block" style={{width:"130px",minHeight:"175px"}} alt="Website portofolio G1"/>
                                            <div className="card-body">
                                                <table className="mb-2 mt-1">
                                                    <tbody>
                                                        <tr>
                                                            <td width="33,3%"><img src="https://webew.id/img/desainWebsite/HTML 5.png" className="img-fluid mx-auto d-block" style={{width:"40px",minHeight:"40px"}} alt="Logo HTML 5"/></td>
                                                            <td width="33,3%"><img src="https://webew.id/img/desainWebsite/css3-logo-png-transparent.png" className="img-fluid mx-auto d-block" style={{width:"40px",minHeight:"40px"}} alt="Logo CSS 3"/></td>
                                                            <td width="33,4%"><img src="https://webew.id/img/desainWebsite/bootstrap-logo.png" className="img-fluid mx-auto d-block" style={{width:"40px",minHeight:"40px"}} alt="Logo Bootstrap 5"/></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <p className="card-text-harga">Harga Saat Ini</p>
                                                <p className="card-text-rupiah">Rp 5.000</p>
                                                <Link to={`/menu-we-website-download/${panggilan}/WP2`} className="btn btn-webew-product mx-auto d-block">Lihat</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-3" id="ucapanUltah">
                                        <div className="card" style={{width: "100%",minHeight:"390px"}}>
                                            <h5 className="card-title text-center mt-2">Web Porto G3</h5>
                                            <img src="https://webew.id/img/desainWebsite/web-porto-g3.png" className="card-img-top mx-auto d-block" style={{width:"130px",minHeight:"175px"}} alt="Website portofolio G1"/>
                                            <div className="card-body">
                                                <table className="mb-2 mt-1">
                                                    <tbody>
                                                        <tr>
                                                            <td width="33,3%"><img src="https://webew.id/img/desainWebsite/HTML 5.png" className="img-fluid mx-auto d-block" style={{width:"40px",minHeight:"40px"}} alt="Logo HTML 5"/></td>
                                                            <td width="33,3%"><img src="https://webew.id/img/desainWebsite/css3-logo-png-transparent.png" className="img-fluid mx-auto d-block" style={{width:"40px",minHeight:"40px"}} alt="Logo CSS 3"/></td>
                                                            <td width="33,4%"><img src="https://webew.id/img/desainWebsite/bootstrap-logo.png" className="img-fluid mx-auto d-block" style={{width:"40px",minHeight:"40px"}} alt="Logo Bootstrap 5"/></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <p className="card-text-harga">Harga Saat Ini</p>
                                                <p className="card-text-rupiah">Rp 5.000</p>
                                                <Link to={`/menu-we-website-download/${panggilan}/WP3`} className="btn btn-webew-product mx-auto d-block">Lihat</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-3 mb-2" id="bikinCV">
                                        <div className="card" style={{width: "100%",minHeight:"390px"}}>
                                            <h5 className="card-title text-center mt-2">Web Porto G4</h5>
                                            <img src="https://webew.id/img/desainWebsite/web-porto-g4.png" className="card-img-top mx-auto d-block" style={{width:"130px",minHeight:"175px"}} alt="Website portofolio G1"/>
                                            <div className="card-body">
                                                <table className="mb-2 mt-1">
                                                    <tbody>
                                                        <tr>
                                                            <td width="33,3%"><img src="https://webew.id/img/desainWebsite/HTML 5.png" className="img-fluid mx-auto d-block" style={{width:"40px",minHeight:"40px"}} alt="Logo HTML 5"/></td>
                                                            <td width="33,3%"><img src="https://webew.id/img/desainWebsite/css3-logo-png-transparent.png" className="img-fluid mx-auto d-block" style={{width:"40px",minHeight:"40px"}} alt="Logo CSS 3"/></td>
                                                            <td width="33,4%"><img src="https://webew.id/img/desainWebsite/bootstrap-logo.png" className="img-fluid mx-auto d-block" style={{width:"40px",minHeight:"40px"}} alt="Logo Bootstrap 5"/></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <p className="card-text-harga">Harga Saat Ini</p>
                                                <p className="card-text-rupiah">Rp 5.000</p>
                                                <Link to={`/menu-we-website-download/${panggilan}/WP4`} className="btn btn-webew-product mx-auto d-block">Lihat</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* NAVBOT */}
                                <MenuNavbarBot/>
                            </section>
                        </div>
                    {/* TUTUP KONTEN */}
                </div>
            {/* TUTUP KONTEN WRAPPER */}
        </div>
    ) // TUTUP KURUNG RETURN
} // TUTUP KURUNG MENU WE WEBSITE
// EKSPOR
export default MenuWeWebsite