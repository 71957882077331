// IMPORT REACT
import React from 'react'
// IMPORT LINK
import { Link } from 'react-router-dom'
// IMPORT NAVBAR BOTTOM
import NavbarBot from '../../Layout/NavbarBot.jsx'
// WE PERNIKAHAN
const WePernikahan = () => { // KURUNG BUKA WE PERNIKAHAN
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div className='background-home' style={{marginTop:'55px'}}>
            {/* INFO */}
                <section id='home'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 col-lg-6 px-3'>
                                <h2 className='title-webew-home'>UD Pernikahan</h2>
                                <p className='text-webew-home'>Bikin undangan digital disini, tinggal pilih tema undangan digital, isi form data dengan benar, dan undangan digital sudah jadi</p>
                                <div className='row'>
                                    <div className='col-6'>
                                        <a href='/' className='btn btn-webew-home' aria-label='Kembali webew.id'>Kembali</a>
                                    </div>
                                    <div className='col-6'>
                                        <a href='#undanganDigital' className='btn btn-webew-home' aria-label='Produk webew.id'>Desain</a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-lg-6'>
                                <img src='https://webew.id/img/19212427_Business people arranging appointment in digital booking app.webp' className='img-webew-home mx-auto d-block mt-4' style={{width: '100%',minHeight:'200px'}} alt='by Freepik on freepik'/>
                            </div>
                        </div>
                    </div>    
                </section>
            {/* TUTUP INFO */}
            <img src='https://webew.id/img/bg-lengkung.png' className='img-bg-lengkung'  style={{width: '100%',minHeight:'360px'}} alt='bg lengkung'/>
            {/* JENIS WE PERNIKAHAN */}
                <section id='undanganDigital' style={{marginTop:'-70px'}}>
                    <div className='container'>
                        <h6 className='fw-bold text-center card-text-rupiah'>Note : Tidak ada masa aktif ya, kamu bisa membuka kapan saja jika mau</h6>
                        <div className='row'>
                            <div className='col-6 col-md-4 col-lg-3' id='undanganDigital'>
                                <div className='card' style={{width: '100%',minHeight:'390px'}}>
                                    <h5 className='card-title text-center mt-2'>Aglaonema</h5>
                                    <img src='img/undanganDigital/UndanganDigitalPernikahan/aglaonema.png' className='mx-auto d-block mt-1' style={{width:'125px', minHeight:'175px'}} alt='Undangan Digital Pernikahan Aglaonema'/>
                                    <div className='card-body'>
                                        <p className='card-text-harga'>Harga Saat Ini</p>
                                        <p className='card-text-rupiah'>Rp 45.000</p>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6'>
                                                <Link to={'/we-pernikahan/aglaonema'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <a href='https://webew.id/login' className='btn btn-webew-product mt-1 mb-1' aria-label='Produk webew.id'>Buat</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 col-md-4 col-lg-3' id='undanganDigital'>
                                <div className='card' style={{width: '100%',minHeight:'390px'}}>
                                    <h5 className='card-title text-center mt-2'>Agapanthus</h5>
                                    <img src='img/undanganDigital/UndanganDigitalPernikahan/agapanthus.png' className='mx-auto d-block mt-1' style={{width:'125px', minHeight:'175px'}} alt='Undangan Digital Pernikahan Agapanthus'/>
                                    <div className='card-body'>
                                        <p className='card-text-harga'>Harga Saat Ini</p>
                                        <p className='card-text-rupiah'>Rp 45.000</p>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6'>
                                                <Link to={'/we-pernikahan/agapanthus'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <a href='https://webew.id/login' className='btn btn-webew-product mt-1 mb-1' aria-label='Produk webew.id'>Buat</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 col-md-4 col-lg-3' id='undanganDigital'>
                                <div className='card' style={{width: '100%',minHeight:'390px'}}>
                                    <h5 className='card-title text-center mt-2'>Alamanda</h5>
                                    <img src='img/undanganDigital/UndanganDigitalPernikahan/alamanda.png' className='mx-auto d-block mt-1' style={{width:'125px', minHeight:'175px'}} alt='Undangan Digital Pernikahan Alamanda'/>
                                    <div className='card-body'>
                                        <p className='card-text-harga'>Harga Saat Ini</p>
                                        <p className='card-text-rupiah'>Rp 45.000</p>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6'>
                                                <Link to={'/we-pernikahan/alamanda'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <a href='https://webew.id/login' className='btn btn-webew-product mt-1 mb-1' aria-label='Produk webew.id'>Buat</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 col-md-4 col-lg-3' id='undanganDigital'>
                                <div className='card' style={{width: '100%',minHeight:'390px'}}>
                                    <h5 className='card-title text-center mt-2'>Amaryllis</h5>
                                    <img src='img/undanganDigital/UndanganDigitalPernikahan/amaryllis.png' className='mx-auto d-block mt-1' style={{width:'125px', minHeight:'175px'}} alt='Undangan Digital Pernikahan Amaryllis'/>
                                    <div className='card-body'>
                                        <p className='card-text-harga'>Harga Saat Ini</p>
                                        <p className='card-text-rupiah'>Rp 45.000</p>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6'>
                                                <Link to={'/we-pernikahan/amaryllis'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <a href='https://webew.id/login' className='btn btn-webew-product mt-1 mb-1' aria-label='Produk webew.id'>Buat</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-6 col-md-4 col-lg-3' id='undanganDigital'>
                                <div className='card' style={{width: '100%',minHeight:'390px'}}>
                                    <h5 className='card-title text-center mt-2'>Bnw 1</h5>
                                    <img src='img/undanganDigital/UndanganDigitalPernikahan/bnw1.png' className='mx-auto d-block mt-1' style={{width:'125px', minHeight:'175px'}} alt='Undangan Digital Pernikahan bnw1'/>
                                    <div className='card-body'>
                                        <p className='card-text-harga'>Harga Saat Ini</p>
                                        <p className='card-text-rupiah'>Rp 45.000</p>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6'>
                                                <Link to={'/we-pernikahan/bnw1'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <a href='https://webew.id/login' className='btn btn-webew-product mt-1 mb-1' aria-label='Produk webew.id'>Buat</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 col-md-4 col-lg-3' id='undanganDigital'>
                                <div className='card' style={{width: '100%',minHeight:'390px'}}>
                                    <h5 className='card-title text-center mt-2'>Bnw 2</h5>
                                    <img src='img/undanganDigital/UndanganDigitalPernikahan/bnw2.png' className='mx-auto d-block mt-1' style={{width:'125px', minHeight:'175px'}} alt='Undangan Digital Pernikahan bnw2'/>
                                    <div className='card-body'>
                                        <p className='card-text-harga'>Harga Saat Ini</p>
                                        <p className='card-text-rupiah'>Rp 45.000</p>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6'>
                                                <Link to={'/we-pernikahan/bnw2'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <a href='https://webew.id/login' className='btn btn-webew-product mt-1 mb-1' aria-label='Produk webew.id'>Buat</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 col-md-4 col-lg-3' id='undanganDigital'>
                                <div className='card' style={{width: '100%',minHeight:'390px'}}>
                                    <h5 className='card-title text-center mt-2'>Bnw 3</h5>
                                    <img src='img/undanganDigital/UndanganDigitalPernikahan/bnw3.png' className='mx-auto d-block mt-1' style={{width:'125px', minHeight:'175px'}} alt='Undangan Digital Pernikahan bnw3'/>
                                    <div className='card-body'>
                                        <p className='card-text-harga'>Harga Saat Ini</p>
                                        <p className='card-text-rupiah'>Rp 45.000</p>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6'>
                                                <Link to={'/we-pernikahan/bnw3'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <a href='https://webew.id/login' className='btn btn-webew-product mt-1 mb-1' aria-label='Produk webew.id'>Buat</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 col-md-4 col-lg-3' id='undanganDigital'>
                                <div className='card' style={{width: '100%',minHeight:'390px'}}>
                                    <h5 className='card-title text-center mt-2'>Bnw 4</h5>
                                    <img src='img/undanganDigital/UndanganDigitalPernikahan/bnw4.png' className='mx-auto d-block mt-1' style={{width:'125px', minHeight:'175px'}} alt='Undangan Digital Pernikahan bnw4'/>
                                    <div className='card-body'>
                                        <p className='card-text-harga'>Harga Saat Ini</p>
                                        <p className='card-text-rupiah'>Rp 45.000</p>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6'>
                                                <Link to={'/we-pernikahan/bnw4'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <a href='https://webew.id/login' className='btn btn-webew-product mt-1 mb-1' aria-label='Produk webew.id'>Buat</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-6 col-md-4 col-lg-3' id='undanganDigital'>
                                <div className='card' style={{width: '100%',minHeight:'390px'}}>
                                    <h5 className='card-title text-center mt-2'>Fotografi</h5>
                                    <img src='img/undanganDigital/UndanganDigitalPernikahan/fotografi.png' className='mx-auto d-block mt-1' style={{width:'125px', minHeight:'175px'}} alt='Undangan Digital Pernikahan fotografi'/>
                                    <div className='card-body'>
                                        <p className='card-text-harga'>Harga Saat Ini</p>
                                        <p className='card-text-rupiah'>Rp 45.000</p>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6'>
                                                <Link to={'/we-pernikahan/fotografi'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <a href='https://webew.id/login' className='btn btn-webew-product mt-1 mb-1' aria-label='Produk webew.id'>Buat</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 col-md-4 col-lg-3' id='undanganDigital'>
                                <div className='card' style={{width: '100%',minHeight:'390px'}}>
                                    <h5 className='card-title text-center mt-2'>Bike</h5>
                                    <img src='img/undanganDigital/UndanganDigitalPernikahan/bike.png' className='mx-auto d-block mt-1' style={{width:'125px', minHeight:'175px'}} alt='Undangan Digital Pernikahan bike'/>
                                    <div className='card-body'>
                                        <p className='card-text-harga'>Harga Saat Ini</p>
                                        <p className='card-text-rupiah'>Rp 45.000</p>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6'>
                                                <Link to={'/we-pernikahan/bike'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <a href='https://webew.id/login' className='btn btn-webew-product mt-1 mb-1' aria-label='Produk webew.id'>Buat</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 col-md-4 col-lg-3' id='undanganDigital'>
                                <div className='card' style={{width: '100%',minHeight:'390px'}}>
                                    <h5 className='card-title text-center mt-2'>Football</h5>
                                    <img src='img/undanganDigital/UndanganDigitalPernikahan/football.png' className='mx-auto d-block mt-1' style={{width:'125px', minHeight:'175px'}} alt='Undangan Digital Pernikahan football'/>
                                    <div className='card-body'>
                                        <p className='card-text-harga'>Harga Saat Ini</p>
                                        <p className='card-text-rupiah'>Rp 45.000</p>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6'>
                                                <Link to={'/we-pernikahan/football'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <a href='https://webew.id/login' className='btn btn-webew-product mt-1 mb-1' aria-label='Produk webew.id'>Buat</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 col-md-4 col-lg-3' id='undanganDigital'>
                                <div className='card' style={{width: '100%',minHeight:'390px'}}>
                                    <h5 className='card-title text-center mt-2'>Badminton</h5>
                                    <img src='img/undanganDigital/UndanganDigitalPernikahan/badminton.png' className='mx-auto d-block mt-1' style={{width:'125px', minHeight:'175px'}} alt='Undangan Digital Pernikahan badminton'/>
                                    <div className='card-body'>
                                        <p className='card-text-harga'>Harga Saat Ini</p>
                                        <p className='card-text-rupiah'>Rp 45.000</p>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6'>
                                                <Link to={'/we-pernikahan/badminton'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <a href='https://webew.id/login' className='btn btn-webew-product mt-1 mb-1' aria-label='Produk webew.id'>Buat</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-6 col-md-4 col-lg-3' id='undanganDigital'>
                                <div className='card' style={{width: '100%',minHeight:'390px'}}>
                                    <h5 className='card-title text-center mt-2'>BleGold</h5>
                                    <img src='img/undanganDigital/UndanganDigitalPernikahan/blegold.png' className='mx-auto d-block mt-1' style={{width:'125px', minHeight:'175px'}} alt='Undangan Digital Pernikahan blegold'/>
                                    <div className='card-body'>
                                        <p className='card-text-harga'>Harga Saat Ini</p>
                                        <p className='card-text-rupiah'>Rp 45.000</p>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6'>
                                                <Link to={'/we-pernikahan/blegold'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <a href='https://webew.id/login' className='btn btn-webew-product mt-1 mb-1' aria-label='Produk webew.id'>Buat</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 col-md-4 col-lg-3' id='undanganDigital'>
                                <div className='card' style={{width: '100%',minHeight:'390px'}}>
                                    <h5 className='card-title text-center mt-2'>BlePink</h5>
                                    <img src='img/undanganDigital/UndanganDigitalPernikahan/blepink.png' className='mx-auto d-block mt-1' style={{width:'125px', minHeight:'175px'}} alt='Undangan Digital Pernikahan blepink'/>
                                    <div className='card-body'>
                                        <p className='card-text-harga'>Harga Saat Ini</p>
                                        <p className='card-text-rupiah'>Rp 45.000</p>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6'>
                                                <Link to={'/we-pernikahan/blepink'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <a href='https://webew.id/login' className='btn btn-webew-product mt-1 mb-1' aria-label='Produk webew.id'>Buat</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 col-md-4 col-lg-3' id='undanganDigital'>
                                <div className='card' style={{width: '100%',minHeight:'390px'}}>
                                    <h5 className='card-title text-center mt-2'>BleTosca</h5>
                                    <img src='img/undanganDigital/UndanganDigitalPernikahan/bletosca.png' className='mx-auto d-block mt-1' style={{width:'125px', minHeight:'175px'}} alt='Undangan Digital Pernikahan bletosca'/>
                                    <div className='card-body'>
                                        <p className='card-text-harga'>Harga Saat Ini</p>
                                        <p className='card-text-rupiah'>Rp 45.000</p>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6'>
                                                <Link to={'/we-pernikahan/bletosca'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <a href='https://webew.id/login' className='btn btn-webew-product mt-1 mb-1' aria-label='Produk webew.id'>Buat</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 col-md-4 col-lg-3' id='undanganDigital'>
                                <div className='card' style={{width: '100%',minHeight:'390px'}}>
                                    <h5 className='card-title text-center mt-2'>BleWhite</h5>
                                    <img src='img/undanganDigital/UndanganDigitalPernikahan/blewhite.png' className='mx-auto d-block mt-1' style={{width:'125px', minHeight:'175px'}} alt='Undangan Digital Pernikahan blewhite'/>
                                    <div className='card-body'>
                                        <p className='card-text-harga'>Harga Saat Ini</p>
                                        <p className='card-text-rupiah'>Rp 45.000</p>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6'>
                                                <Link to={'/we-pernikahan/blewhite'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <a href='https://webew.id/login' className='btn btn-webew-product mt-1 mb-1' aria-label='Produk webew.id'>Buat</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-6 col-md-4 col-lg-3' id='undanganDigital'>
                                <div className='card' style={{width: '100%',minHeight:'390px'}}>
                                    <h5 className='card-title text-center mt-2'>Cute</h5>
                                    <img src='img/undanganDigital/UndanganDigitalPernikahan/cute.png' className='mx-auto d-block mt-1' style={{width:'125px', minHeight:'175px'}} alt='Undangan Digital Pernikahan Amaryllis'/>
                                    <div className='card-body'>
                                        <p className='card-text-harga'>Harga Saat Ini</p>
                                        <p className='card-text-rupiah'>Rp 45.000</p>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6'>
                                                <Link to={'/we-pernikahan/cute'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <a href='https://webew.id/login' className='btn btn-webew-product mt-1 mb-1' aria-label='Produk webew.id'>Buat</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 col-md-4 col-lg-3' id='undanganDigital'>
                                <div className='card' style={{width: '100%',minHeight:'390px'}}>
                                    <h5 className='card-title text-center mt-2'>Adorable</h5>
                                    <img src='img/undanganDigital/UndanganDigitalPernikahan/adorable.png' className='mx-auto d-block mt-1' style={{width:'125px', minHeight:'175px'}} alt='Undangan Digital Pernikahan Amaryllis'/>
                                    <div className='card-body'>
                                        <p className='card-text-harga'>Harga Saat Ini</p>
                                        <p className='card-text-rupiah'>Rp 45.000</p>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6'>
                                                <Link to={'/we-pernikahan/adorable'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <a href='https://webew.id/login' className='btn btn-webew-product mt-1 mb-1' aria-label='Produk webew.id'>Buat</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 col-md-4 col-lg-3' id='undanganDigital'>
                                <div className='card' style={{width: '100%',minHeight:'390px'}}>
                                    <h5 className='card-title text-center mt-2'>Beauty</h5>
                                    <img src='img/undanganDigital/UndanganDigitalPernikahan/beauty.png' className='mx-auto d-block mt-1' style={{width:'125px', minHeight:'175px'}} alt='Undangan Digital Pernikahan Amaryllis'/>
                                    <div className='card-body'>
                                        <p className='card-text-harga'>Harga Saat Ini</p>
                                        <p className='card-text-rupiah'>Rp 45.000</p>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6'>
                                                <Link to={'/we-pernikahan/beauty'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <a href='https://webew.id/login' className='btn btn-webew-product mt-1 mb-1' aria-label='Produk webew.id'>Buat</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 col-md-4 col-lg-3' id='undanganDigital'>
                                <div className='card' style={{width: '100%',minHeight:'390px'}}>
                                    <h5 className='card-title text-center mt-2'>Cool</h5>
                                    <img src='img/undanganDigital/UndanganDigitalPernikahan/cool.png' className='mx-auto d-block mt-1' style={{width:'125px', minHeight:'175px'}} alt='Undangan Digital Pernikahan Amaryllis'/>
                                    <div className='card-body'>
                                        <p className='card-text-harga'>Harga Saat Ini</p>
                                        <p className='card-text-rupiah'>Rp 45.000</p>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6'>
                                                <Link to={'/we-pernikahan/cool'} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <a href='https://webew.id/login' className='btn btn-webew-product mt-1 mb-1' aria-label='Produk webew.id'>Buat</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            {/* TUTUP JENIS WE PERNIKAHAN */}
            <img src='https://webew.id/img/bg-lengkung.png' className='img-bg-lengkung-bawah'  style={{width: '100%',minHeight:'360px'}} alt='bg lengkung'/>
            {/* CREDIT IMAGE */}
                <section id='credit-image'>
                    <div className='container mb-4'>
                        <h3 className='judul-credit text-center'>Image By</h3>
                        <div className='row justify-content-center'>
                            <div className='col-8 col-sm-6 col-md-4 col-lg-3 mb-3'>
                                <a href='https://www.freepik.com/free-vector/business-people-arranging-appointment-digital-booking-app_19212427.htm#page=4&query=app&position=22&from_view=author' aria-label='Link mengarah ke pembuat image yaitu Pch-vector Freepik'>
                                    <div className='card' style={{width: '100%',minHeight:'150px'}}>
                                        <img src='https://webew.id/img/credit/19212427_Business people arranging appointment in digital booking app.webp' className='mx-auto d-block'  style={{width: '100%',minHeight:'120px'}} alt='by Pch-vector on Freepik'/>
                                        <div className='card-body'>
                                            <p className='text-center'>Pch-vector on Freepik</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            {/* TUTUP CREDIT IMAGE */}
            {/* NAVBAR BOTTOM */}
                <NavbarBot/>
            {/* TUTUP NAVBAR BOTTOM */}
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP WE PERNIKAHAN
// EKSPOR
export default WePernikahan