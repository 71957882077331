// IMPORT REACT
import React from 'react'
// IMPORT LINK
import { Link } from "react-router-dom"
// NAVBAR BOTTOM
const NavbarBot = () => { // KURUNG BUKA NAVBAR BOTTOM
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div>
            {/* NAVBAR BOTTOM */}
            <div className='navbot'>
                <div className='container fixed-bottom' style={{width:'100%',height:'auto',background:'white', padding:'10px 15px 10px 15px',borderRadius:'50px',boxShadow: '0 0 20px 7px #dddddd53'}}>
                    <div className='row'>
                        <div className='col-4'><Link className='btn btn-webew-product'  to={'/'} aria-label='Produk webew.id' style={{padding:'4px 0 4px 0',marginTop:'3px'}}><i class='bi bi-caret-left-fill' style={{color:'white'}}></i></Link></div>
                        <div className='col-4'>
                            <div class='dropdown-center'>
                                <button class='btn btn-webew-product' type='button' data-bs-toggle='dropdown' aria-expanded='false' style={{padding:'7px 0 7px 0'}}><i class='bi bi-grid-fill' style={{color:'white'}}></i></button>
                                <ul class='dropdown-menu text-center mb-3'>
                                    <li><Link className='dropdown-item' to={'/'} aria-label='Home' style={{fontSize:'13pt'}}>Home</Link></li>
                                    <li><Link className='dropdown-item' to={'/we-aqiqah'} aria-label='Undangan Digital' style={{fontSize:'13pt'}}>We Aqiqah</Link></li>
                                    <li><Link className='dropdown-item' to={'/we-cv'} aria-label='Bikin CV' style={{fontSize:'13pt'}}>We CV</Link></li>
                                    <li><Link className='dropdown-item' to={'/we-khodam'} aria-label='Bikin Khodam' style={{fontSize:'13pt'}}>We Khodam</Link></li>
                                    <li><Link className='dropdown-item' to={'/we-motivasi'} aria-label='motivasi' style={{fontSize:'13pt'}}>We Motivasi</Link></li>
                                    <li><Link className='dropdown-item' to={'/we-pernikahan'} aria-label='Undangan Digital' style={{fontSize:'13pt'}}>We Pernikahan</Link></li>
                                    <li><Link className='dropdown-item' to={'/we-ultah'} aria-label='Ucapan Ultah' style={{fontSize:'13pt'}}>We Ultah</Link></li>
                                    <li><Link className='dropdown-item' to={'/we-website'} aria-label='Desain Website' style={{fontSize:'13pt'}}>We Website</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-4'><Link className='btn btn-webew-product' to={'https://webew.id/login'} aria-label='Produk webew.id' style={{padding:'4px 0 4px 0',marginTop:'3px'}}><i class='bi bi-cart4' style={{color:'white'}}></i></Link></div>
                    </div>
                </div>
            </div>
            {/* TUTUP NAVBAR BOTTOM */}
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP NAVBAR BOTTOM
// EKSPOR
export default NavbarBot